import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container'
import Classes from './index.module.css'
import { Link } from 'react-router-dom';
import HowItWorks from '../../components/HowItWorks';
import faqJson from '../NewFaq/faqaboutus.json';


const About = (props) => {

    const imgCDNURL = process.env.REACT_APP_LOWRATES_RESOURCES;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const QnAList = ({ qaList }) => {
        const [expandedQuestion, setExpandedQuestion] = useState(null);
        
        const handleQuestionClick = (index) => {
            setExpandedQuestion((prevIndex) => (prevIndex === index ? null : index));
        };
        
        return (
            <ul>
                {qaList[0].qa.map((item, index) => (
                    <li className={Classes.lowratesactivityfaqli} key={index}>
                        <QuestionItem
                            question={item.question}
                            answer={item.answer}
                            isExpanded={expandedQuestion === index}
                            onQuestionClick={() => handleQuestionClick(index)}
                            isUnderlined={expandedQuestion === index}
                        />
                    </li>
                ))}
            </ul>
        );
    };

    const QuestionItem = ({ question, answer, isExpanded, onQuestionClick, isUnderlined }) => {
        const [clicked, setClicked] = useState(false);
        const [isHovered, setIsHovered] = useState(false);

        return (
            <div>
                <div
                    onClick={onQuestionClick}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    style={{
                        cursor: 'pointer',
                        textDecoration: isUnderlined || isHovered ? 'underline' : 'none',
                    }}
                >
                    {question}
                </div>
                {isExpanded && <div><p className={Classes.lowratesactivityfaqanswer}>{answer}</p></div>}
            </div>
        );
    };

    const ProgressBar = () => {
        const [progress, setProgress] = useState(0);
        const steps = [
            '1. START YOUR APPLICATION',
            '2. REVIEW YOUR OPTIONS WITH A MEMBER OF OUR TEAM',
            '3. CONFIRM YOUR INFORMATION',
            '4. CLOSE ON YOUR NEW LOAN',
            '5. CONTINUE WORKING WITH OUR TEAM THROUGHOUT THE LIFETIME OF YOUR LOAN',
        ];

        const stepPercentages = [20, 40, 60, 80, 100];

        useEffect(() => {
            const interval = setInterval(() => {
                setProgress((prevProgress) => (prevProgress < 100 ? prevProgress + 20 : 100));
            }, 1200);

            return () => clearInterval(interval);
        }, []);

        return (
            <div className={Classes.progress_levels}>
                {steps.map((step, index) => (
                    <div
                        key={index}
                        className={`${Classes.progress_box} wow`}
                        data-wow-delay={`${index * 200}ms`}
                        data-wow-duration={`${(index + 1) * 300}ms`}
                    >
                        <h5 className={Classes.box_title}>{step}</h5>
                        <br />
                        <div className={Classes.inner}>
                            <div className={Classes.bar}>
                                <div className={Classes.bar_inner}>
                                    <div className={Classes.bar_fill} style={{ width: `${stepPercentages[index]}%` }}>
                                        <div className={Classes.percentContainer}>
                                            <div className={Classes.percent}>{stepPercentages[index]}%</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <article className={Classes.aboutWrapper} style={{ backgroundColor: '#FCFCFC' }}>
            <Helmet>
                <title>About Us | LowRates</title>
                <meta name="description" content="LowRates, Mortgage Loans" />
                <meta name="keywords" content="LowRates, Mortgage, Loans, Swmc, SunWestMortgage, Mortgage Possible" />
            </Helmet>

            <img
                src={imgCDNURL + "aboutbannerimage.webp"}
                className={Classes.aboutbannerimage}

            />

            <img
                src={imgCDNURL + "aboutbannerimagemobile.webp"}
                className={Classes.aboutbannerimagemobile}

            />
            <div className={Classes.imageContainer}>
                <div>
                    <a href="https://seemyloanstatus.com/ApplyNow/?c=lowrates" target="_blank" title="Click Here To ApplyNow">
                        <img
                            src={imgCDNURL + "applynowaboutus.webp"}
                            className={Classes.applynowaboutusimage}
                        />
                    </a>
                </div>
                <div>
                    <a href="tel:+18445697283" title="Click Here To Talk to Us">
                        <img
                            src={imgCDNURL + "talktous.webp"}
                            className={Classes.talktousimage}
                        />
                    </a>
                </div>
            </div>
            <div className={Classes.aboutusdiv}>
                <Container className={Classes.aboutuscontainer}>
                    <Row className={Classes.whychooserow}>
                        <Col lg={12} md={12} sm={12}>
                            <h2 className={Classes.aboutHeading}>ABOUT US</h2>
                            <h3 className={Classes.aboutHeader}>Why Choose LowRates?</h3>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <p className={Classes.aboutPara}>At LowRates, we dedicate ourselves to offering the best service to our customers. To accomplish this, we empower our Loan Officers so that they can find great rates and provide the best fitting loan options to each customer – at amazing speeds. Our focus on technology has given us an edge in the mortgage industry to offer some of the fastest turn times available – so you can get into the home of your dreams sooner!</p>
                            <p className={Classes.aboutPara}>Our commitment to our core values of people, experience, technology, and product stem from the values of our parent company Sun West Mortgage Company, Inc. Sun West was founded in 1980 with the perspective of “Clients First” and the desire to make the mortgage process easy and stress free for prospective homeowners. Since then, Sun West has grown to have a 9 Billion+ Loan servicing portfolio and is licensed to lend in 50 states, Puerto Rico, and the US Virgin Islands.</p>

                        </Col>
                        {/* <Col sm={6}>
                        <iframe src="https://www.youtube.com/embed/NpjUH3GPFEg" frameborder="0" style={{ width: '100%', height: '300px' }} />
                    </Col> */}
                    </Row>
                    <Row style={{ marginTop: '50px', justifyContent: 'center' }}>
                        <Col lg={4} md={4} sm={12}>
                            <div className={Classes.aboutFeatureCard}>
                                <img src="https://lowrates-staging.s3.amazonaws.com/images/workprocess.jpg" style={{ width: '100%' }} />
                                <div className={Classes.overlay + " " + Classes.textCenter}>
                                    <h3>Easy as 1.2.3</h3>
                                    <p>We want to educate our clients on the mortgage process in as clear and efficient way as possible.</p>
                                    <Link to='/mortgage-process' className={Classes.overlayButton}>
                                        <span>Read More</span>
                                    </Link>
                                </div>
                                <div className={Classes.titleBar + " " + Classes.flexDisp}>
                                    <span>Our Mortgage Process</span>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={4} sm={12}>
                            <div className={Classes.aboutFeatureCard}>
                                <img src="https://lowrates-staging.s3.amazonaws.com/images/home.jpg" style={{ width: '100%' }} />
                                <div className={Classes.overlay + " " + Classes.textCenter}>
                                    <h3>Get Ready to Buy</h3>
                                    <p>How do you know if you and your family are ready to commit to a new home?</p>
                                    <Link to='/home-buying' className={Classes.overlayButton}>
                                        <span>Read More</span>
                                    </Link>
                                </div>
                                <div className={Classes.titleBar + " " + Classes.flexDisp}>
                                    <span>Home Buyer Guide</span>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={4} sm={12}>
                            <div className={Classes.aboutFeatureCard}>
                                <img src="https://lowrates-staging.s3.amazonaws.com/images/finance.jpg" style={{ width: '100%' }} />
                                <div className={Classes.overlay + " " + Classes.textCenter}>
                                    <h3>Refinance Loans</h3>
                                    <p>We strive to provide you with all the resources necessary.</p>
                                    <Link to='/refinancing-guide' className={Classes.overlayButton}>
                                        <span>Read More</span>
                                    </Link>
                                </div>
                                <div className={Classes.titleBar + " " + Classes.flexDisp}>
                                    <span>Refinance Guide</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className={Classes.loanhowitworksDiv}>

                <Container className={Classes.loanhowitworksContainer}>
                    <HowItWorks />
                </Container>
            </div>

            <div className={Classes.valuemissiondiv}>
                <Container className={Classes.valuemissioncontainer}>
                    <Row className={Classes.valuemissionrow}>
                        <Col lg={4} md={4} sm={12}>
                            <h2><strong className={Classes.valuemissionheadingmobile}>Our Values</strong></h2>
                            <ul >
                                <li>We value people at every stage of the loan process</li>
                                <li>Experience for more guidance, less stress</li>
                                <li>Show more transparency, less confusion</li>
                                <li>Create a better mortgage experience</li>
                                <li>Our technology to get you through Faster</li>
                                <li>Get you the best offers</li>
                                <li>To customize your mortgage plan according to your goals</li>
                                <li>Get you home as soon as possible</li>
                                <li>We Are Maintain Our Day Scheduling.</li>
                                <li>We Are Providing Free Consultations.</li>
                                <li>Qualified People</li>
                                <li>Customer Satisfaction</li>
                            </ul>
                        </Col>

                        <Col lg={4} md={4} sm={12}>

                            <img src="https://d2w24n4g34usfg.cloudfront.net/lowrates/images/updatedArtboard1.png" className={Classes.valuemissionimage} />

                        </Col>



                        <Col lg={4} md={4} sm={12}>
                            <h2><strong className={Classes.valuemissionheadingmobile}>Our Mission</strong></h2>
                            <p>Our Mission at LowRates is to foster an environment of unparalleled support and technology advancement for our loan officers so that they can be equipped to provide their clients with the best customer service and most competitive financing options available.</p>
                            <h2><strong className={Classes.valuemissionheadingmobile}>Leadership</strong></h2>
                            <p>Our leadership truly sets us apart. Our combined experience is at work everyday strengthening our loan officers and enabling our clients to reach their goals.</p>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className={Classes.ourprocessdiv}>
                <Container className={Classes.ourprocesscontainer}>
                    <Row className={Classes.ourprocessrow}>
                        <Col lg={12} md={12} sm={12} className="mt-5 mb-5">
                            <h2><strong className={Classes.valuemissionheadingmobile}>Our Mortgage Process</strong></h2>

                            <p>At LowRates, we want to educate our clients on the mortgage process in as clear and efficient way as possible. To prevent leaving any of our clients in the dark, we created a detailed list to explain how the mortgage loan process works with LowRates. Our team offers several ways to apply for a new home loan. Use our online application to send in your request or contact our office via phone or email. We’ll match you up with one of our team members who will get you started on the mortgage loan process. Prepare small details like your mortgage loan goals, household income, and total assets to share with our team and we’ll prepare your options. Once your application is complete, we’ll take a look at your family’s financial situation and home loan needs and pair you with a list of home loans that fits the cost, rate, and benefits you’ll need.</p>

                            <p>A member of our team will review your financial information and confirm that everything looks good. Once everything is confirmed, we will move forward with the application. Once your application is accepted, you are ready to close your loan. Whether you’re ready that same day or need extra time, our loan officers will always be available to you whenever you choose to close on your new loan. After you close on your loan, you’ll have full access to our team and resources throughout the lifespan of your loan, like easy access to your loan information and paperwork as well as mortgage advice from our officers. If your financial situation ever changes, feel free to update your payment schedule to better accommodate your new finances. Our team is proud to offer continued service to our clients, from their first loan application to the very last mortgage payment.</p>
                        </Col>

                        <Col className={Classes.progressbarcolumn} lg={12} md={12} sm={12}>
                            <ProgressBar />
                        </Col>
                    </Row>
                </Container>
            </div>


            <div className={Classes.lowratesactivitydiv}>
                <Container className={Classes.lowratesactivitycontainer}>
                    <Row className={Classes.lowratesactivityrow}>
                        <Col lg={5} md={5} sm={12} className={Classes.lowratesactivityfaq}>
                            <h4><strong className={Classes.valuemissionheadingmobile}>Frequently Asked Questions</strong></h4>
                            <div className={Classes.valuemissionheadingmobile}>
                                <QnAList qaList={faqJson} />
                            </div>
                        </Col>
                        <Col lg={1} md={1} sm={1} ></Col>
                        <Col lg={4} md={4} sm={12} className={Classes.lowratesactivityfaq}>
                            <div className={Classes.lowratesyoutubevideo}>
                                <h5><strong className={Classes.valuemissionheadingmobile}>LowRates Activity</strong></h5>
                                <div className={Classes.lowratesactivityvideo} >
                                    <iframe src="https://www.youtube.com/embed/r5uDIOo5-_o" frameborder="0" style={{ width: '100%', height: '250px' }} />
                                </div>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>

        </article>
    )
}

export default About;