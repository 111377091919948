import React, { memo, useEffect, Suspense, lazy } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import 'iframe-resizer';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import { Rates } from "rate-quotes";
import LeadCaptureForm from '../../components/LeadCaptureForm/';
import Classes from './index.module.css';
import styled from 'styled-components';
import AOS from "aos";
import "aos/dist/aos.css";
import Rectangleonimagemobile from '../../components/GameOnQuotes/images/Rectangleonimagemobile.png';

const GameOnQuotes = React.lazy(() => import('../../components/GameOnQuotes/Loadable'));
const BorrowerLeadForm = lazy(() => import('../../components/BorrowerLeadForm'));
const ResourcesMenu = lazy(() => import('../../components/ResourcesMenu'));
const FeaturesDiv = lazy(() => import('../../components/FeaturesDiv'));
const ServicesDiv = lazy(() => import('../../components/ServicesDiv'));
const TestimonialsDiv = lazy(() => import('../../components/Testimonials'));

AOS.init();

const key = 'Home';

const Homepage = ({ }) => {

  const imgCDNURL = process.env.REACT_APP_LOWRATES_RESOURCES;

  useEffect(() => {
    const iframe = document.getElementById('iframeContactUs');

    if (iframe) {
      window.iFrameResize(
        {
          log: false,
          checkOrigin: false,
          autoResize: true,
          heightCalculationMethod: 'bodyScroll',
        },
        iframe
      );
    }
  }, []);

  const QuoteWrapper = styled.div`
  .react-multi-carousel-dot--active button {
    background: #083f88 !important;
    width: 25px !important;
    border-radius: 10px !important;
  }

  @media (max-width: 991.7px) {
    .react-multi-carousel-dot--active button {   
        margin-bottom:0rem;
    }
}

  @media (min-width: 320px) and (max-width: 389.7px) {
    .react-multi-carousel-dot button {
      margin-right: 0px !important;
    }
    .react-multi-carousel-dot--active button {
      width: 17px !important;
    }
  }

  @media (min-width: 390px) and (max-width: 767.7px) {
    .react-multi-carousel-dot button {
      margin-right: 0px !important;
    }
    .react-multi-carousel-dot--active button {
      width: 17px !important;
    }
  }

  @media (min-width: 768px) and (max-width: 991.7px) {
    .react-multi-carousel-dot button {
      margin-right: 0.5px;
      width: 11px !important;
    }
    .react-multi-carousel-dot--active button {
      width: 13px !important;
    }
  }

  @media (min-width: 992px) and (max-width: 1100px) {
    .react-multi-carousel-dot button {
      margin-right: 2px !important;
    }
    .react-multi-carousel-dot--active button {
      width: 20px !important;
    }
  }
`;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <article>

      <Helmet>
        <title>LowRates.com - Ensuring Faster Tech-Driven Mortgage Solutions</title>

      </Helmet>

      <div id="ratesWrapper" className='shadow-lg'>

        <Container >
          <Row >
            <Col xs={12} sm={12} md={6} xl={6} lg={6} className={Classes.leftBannerDiv}>
              <Suspense fallback={<div>Loading...</div>}>
                <Rates leadSource="NET007" />
              </Suspense>
              <h2 className={Classes.phonetext}><a href='tel:+18445697283'>1-(844)-LOW-RATE</a></h2>
            </Col>
            <img
              src={Rectangleonimagemobile}
              className={`${Classes.imagewrappermobile} px-0`}
            />
            <Col xs={12} sm={12} md={6} xl={6} lg={6} className={Classes.rightBannerDiv}>
              <QuoteWrapper className={Classes.rightImgBox}>
                <Suspense fallback={<div>Loading...</div>}>
                  <GameOnQuotes />
                </Suspense>
              </QuoteWrapper>
            </Col>
          </Row>
        </Container>

      </div>


      <div className={Classes.formContainer}>
        {/* <iframe
          id="iframeContactUs"
          src="https://d2w24n4g34usfg.cloudfront.net/react/contactUs/index.html"
          allow="geolocation"
          frameBorder="0"
          scrolling="no"
          style={{ width: '100%', border: '0' }}
        ></iframe> */}
        <Suspense fallback={<div>Loading...</div>}>
          <BorrowerLeadForm />
        </Suspense>
      </div>

      <Suspense fallback={<div>Loading...</div>}>
        <ResourcesMenu />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <FeaturesDiv />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <ServicesDiv />
      </Suspense>
      <QuoteWrapper >
        <Suspense fallback={<div>Loading...</div>}>
          <TestimonialsDiv />
        </Suspense>
      </QuoteWrapper>
    </article>
  )
}

export default Homepage;
