/*
* Fixed Rate Calculators Page
*/

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import styles from './index.module.css';


const key = 'fixedRateCalculator';

export function FixedRateCalculators({

}) {
  const imgCDNURL = process.env.REACT_APP_LOWRATES_RESOURCES;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <article>
      <Helmet>

        <title>Fixed Rate Calculator | LowRates</title>
        <meta name="description" content="LowRates, Mortgage Loans" />
        <meta name="keywords" content="LowRates, Mortgage, Loans, Swmc, SunWestMortgage, Mortgage Possible" />

      </Helmet>
      <div>
        <img
          src={imgCDNURL + "loanprogrambannerimage.webp"}
          className={styles.loanprogrambannerimage}

        />

        <img
          src={imgCDNURL + "loanprogrambannerimagemobile.webp"}
          className={styles.loanprogrambannerimagemobile}

        />
        <a href="https://seemyloanstatus.com/ApplyNow/?c=lowrates" target="_blank" title="Click Here To ApplyNow">
          <img
            src={imgCDNURL + "applynowbanner.webp"}
            className={styles.applynowbanner}
          />
        </a>
        <div className={styles.reficalculatorStyleDiv}>
          <Container fluid className={styles.pageContainer}>

            <Row className={styles.header}>
              <h1>Fixed-Rate Mortgage Calculator</h1>
              <h2>Simple, Fast Service.</h2>
            </Row>


            {/* displaying an iframe for calculator from an html page that is already created */}
            <div className={styles.iFrameDiv}>
              <iframe src="https://swmc-landingpages.s3.amazonaws.com/mortgage-calculators/Swmc-calculators/fixed-rate-calculator.html" height="1298px" overflow="hidden" className={styles.iFrame}></iframe>
            </div>

          </Container>
        </div>
      </div>
    </article>
  );
}





export default FixedRateCalculators
