/*
* Fixed Rate Calculators Page
*/

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container'
import styles from './index.module.css'
import Forms from '../../components/Form';
import Inputs from '../../components/Inputs';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import styled from 'styled-components'
import { Table } from 'react-bootstrap';
import dayjs from 'dayjs';


const StyleWrapper = styled.div`


.table {
  color: #212529;
  // max-width: 800px;
  margin: 0 auto;
  // border: 1px solid #ecec;
  margin-bottom : 20px;
  font-size: 14px;
  
}

h1{
  text-align: center;
}

.dEknFw form {
  max-width: 500px;
  margin: 0 auto !important;
  padding: 20px 30px !important;
}

`

const key = 'BiweeklyCalculator';

const Schema = yup.object().shape({

  LoanAmount: yup.number('Loan Amount can contain only numbers').required('Loan Amount is required').typeError('Please enter a valid amount'),
  Term: yup.number('Term can contain only numbers').required('Term is required').typeError('Please enter valid term')
    .test(
      'Is positive?',
      'ERROR: The number must be greater than 0!',
      (value) => value > 0
    ),
  InterestRate: yup.number('Interest Rate can contain only numbers').required('Interest Rate is required')
    .typeError('Please enter a valid rate')
    .test(
      'Is positive?',
      'ERROR: The number must be greater than 0!',
      (value) => value > 0
    ),

})

const StyledContainer = styled.div`
  .tableHolder {
    display: flex;
    gap: 10px;
    span {
      width: 125px;
    }
  }
`;


export function BiweeklyCalculator({

}) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const imgCDNURL = process.env.REACT_APP_LOWRATES_RESOURCES;
  const [loanAmount, setLoanAmount] = useState("");
  const [termYear, setTermYear] = useState("");
  const [interestRate, setInterestRate] = useState("")
  const [monthlyPayment, setMonthlyPayment] = useState("")



  const [standardMonthlyPlan, setStandardMonthlyPlan] = useState(null);
  const [trueBiWeeklylyPlan, setTrueBiWeeklylyPlan] = useState(null);
  const [biWeeklyAccelerationPlan, setBiWeeklyAccelerationPlan] = useState(null);
  const [prePayingPlan, setPrePayingPlan] = useState(null);

  const [content, setContent] = useState(null)
  const [displayContent, setDisplayContent] = useState(null)
  const [disclaimer, setDisclaimer] = useState(false)


  function calaculateEMI(int_rate_per_year, term, loan_amount) {
    return (
      loan_amount *
      int_rate_per_year *
      (Math.pow(1 + int_rate_per_year, term) / (Math.pow(1 + int_rate_per_year, term) - 1))
    );
  }
  function formatNumber(num) {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }
  function calculate_loan_balance_RAS(amount_RAS, principal_RAS) {
    const loan_balance_RAS = amount_RAS - principal_RAS;
    return loan_balance_RAS;
  }
  function calculate_loan_balance_RAS_AP(l_amount, principal, add_principal) {
    const loan_balance = l_amount - (principal + add_principal);
    return loan_balance;
  }
  function calculate_interest(amount, rate) {
    const value = (amount * rate) / 12 / 100;
    return value;
  }

  const formatCurrency = (input) => {
    try {
      const value = input
        .toFixed(2)
        .replace(/[^0-9.]/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        .replace(/\./, '#')
        .replace(/\./g, '')
        .replace(/#/, '.')
        .replace(/^/, '$ ');

      return value;
    }

    catch (error) {
      return input
    }
  };

  const calculateStandardMonthlyPlan = (loan_amount, term_year, int_rate) => {

    // (loan_amount = 15000, term_year = 9, int_rate = 4) 
    const value = new Date();
    let startDate = dayjs(value).add(1, 'month').startOf('month');
    const term = term_year * 12;
    const monthlyPayment = calaculateEMI(int_rate / 1200, term_year * 12, loan_amount);
    const emi = formatNumber(monthlyPayment);
    const emi_proper = emi;
    const formattedLoanAmount = formatCurrency(Number(loan_amount))
    const table = [{ principal: '', interest: '', date: '', loanBalance: formattedLoanAmount }];

    let l_amount_RAS = loan_amount;
    let ET_RAS = 0;
    let total_interest_RAS = 0;
    let total_principal_RAS = 0;
    let ET_RAS_months = 0;
    for (let i = 1; i <= term; i++) {
      let tableData = {};
      if (i === 1) {
        tableData = { ...tableData, date: startDate.format('MM/DD/YYYY') };
      } else {
        startDate = startDate.add(1, 'month');
        tableData = { ...tableData, date: startDate.format('MM/DD/YYYY') };
      }
      if (l_amount_RAS < 0.01) {
        break;
      } else {
        ET_RAS = ET_RAS + 1;
      }
      const interest_RAS = calculate_interest(l_amount_RAS, int_rate);
      tableData = { ...tableData, interest: formatNumber(interest_RAS) };
      const principal_RAS = emi_proper - interest_RAS;
      tableData = { ...tableData, principal: formatNumber(principal_RAS) };
      l_amount_RAS = calculate_loan_balance_RAS(l_amount_RAS, principal_RAS);
      tableData = { ...tableData, loanBalance: formatNumber(l_amount_RAS) };
      total_interest_RAS = total_interest_RAS + interest_RAS;
      total_principal_RAS = total_principal_RAS + principal_RAS;
      ET_RAS_months = ET_RAS;
      table.push(tableData);
    }
    const total_amout_per_year_RAS =
      ((total_principal_RAS + total_interest_RAS) / ET_RAS_months) * 12;
    setStandardMonthlyPlan({
      table: table,
      totalInterest: formatNumber(total_interest_RAS),
      totalAmount: formatNumber(total_amout_per_year_RAS),
      totalPrincipal: formatNumber(total_principal_RAS),
      term: ET_RAS_months,
    });
  };
  const calculateTrueBiWeeklylyPlan = (loan_amount, term_year, int_rate) => {
    const value = new Date();
    const startDate = dayjs(value).add(1, 'month').startOf('month');
    let Date1 = startDate;
    let Date2 = startDate;

    // const table = [];
    const formattedLoanAmount = formatCurrency(Number(loan_amount))
    const table = [{ principal: '', interest: '', date: '', loanBalance: formattedLoanAmount }];

    let true_BW_loan_amount = loan_amount;
    let total_interest_True_BW = 0;
    let total_principal_True_BW = 0;
    let ET_True_BW = 0;
    const term_true_BW = term_year * 26;
    const int_rate_per = int_rate / 100;
    const interest_true_BW = int_rate_per / 26;
    let ET_True_BW_months = 0;

    for (let i = 1; i <= 1000; i++) {
      let tableData = {};
      const day = Date1.get('date');
      const date_of_last_day = Date1.endOf('month');
      const new_d = date_of_last_day.get('date');
      if (true_BW_loan_amount < 0.01) {
        break;
      } else {
        ET_True_BW = ET_True_BW + 1;
        if (i === 1) {
          tableData = { ...tableData, date: Date1.format('MM/DD/YYYY') };
        } else {
          if (day === 1) {
            Date2 = Date2.add(14, 'day');
          } else if (day + 14 > new_d) {
            Date2 = date_of_last_day.add(1, 'day');
          } else {
            Date2 = Date1.add(14, 'day');
          }

          tableData = { ...tableData, date: Date2.format('MM/DD/YYYY') };
        }

        const payment_true_BW =
          loan_amount *
          interest_true_BW *
          (Math.pow(1 + interest_true_BW, term_true_BW) /
            (Math.pow(1 + interest_true_BW, term_true_BW) - 1));
        tableData = { ...tableData, payment: formatNumber(payment_true_BW) };
        const interest_true_BW_payment = (true_BW_loan_amount * int_rate) / 26 / 100;
        tableData = { ...tableData, interest: formatNumber(interest_true_BW_payment) };
        const principal_true_BW = payment_true_BW - interest_true_BW_payment;
        tableData = { ...tableData, principal: formatNumber(principal_true_BW) };
        true_BW_loan_amount = true_BW_loan_amount - principal_true_BW;
        tableData = { ...tableData, loanBalance: formatNumber(true_BW_loan_amount) };
        ET_True_BW_months = Math.round((ET_True_BW / 26) * 12);
        total_interest_True_BW = total_interest_True_BW + interest_true_BW_payment;
        total_principal_True_BW = total_principal_True_BW + principal_true_BW;
        table.push(tableData);

        const temp = Date1;
        Date1 = Date2;
        Date2 = temp;
      }
    }
    const total_amout_per_year_true_BW =
      ((total_principal_True_BW + total_interest_True_BW) / ET_True_BW_months) * 12;
    setTrueBiWeeklylyPlan({
      table: table,
      totalInterest: formatNumber(total_interest_True_BW),
      totalAmount: formatNumber(total_amout_per_year_true_BW),
      totalPrincipal: formatNumber(total_principal_True_BW),
      term: ET_True_BW_months,
    });
  };
  const calculateBiWeeklyAccelerationPlan = (loan_amount, term_year, int_rate) => {
    const value = new Date();
    const startDate = dayjs(value).add(1, "month").startOf("month");
    let Date2 = startDate;
    let Date3 = startDate;
    let f_pay = 0;
    let bal = 0;
    let princ = 0;
    let int = 0;
    let pay_credit = 0;
    const monthlyPayment = calaculateEMI(
      int_rate / 1200,
      term_year * 12,
      loan_amount
    );
    const emi = monthlyPayment;

    let table = [];
    const formattedLoanAmount = formatCurrency(Number(loan_amount))
    table = [{ loanBalance: formattedLoanAmount }];


    let bi_loan_amount = loan_amount;
    let total_interest_BW = 0;
    let total_principal_BW = 0;
    let ET_Accelerator_BW = 0;
    let ET_Accelerator_BW_months = 0;

    for (let i = 1; i <= 1000; i++) {
      let tableData = {};
      const day = Date2.get("date");
      const date_of_last_day = Date2.endOf("month");

      const newdate = date_of_last_day;

      const new_d = newdate.get("date");

      if (bi_loan_amount < 0.01) {
        break;
      } else {
        if (i === 1) {
          tableData = { ...tableData, date: Date2.format("MM/DD/YY") };
        } else {
          // first of the month
          if (day === 1) {
            Date3 = Date3.add(14, "day");
          }
          // 15 day of the month
          else if (day + 14 > new_d) {
            Date3 = date_of_last_day.add(1, "day");
          } else {
            Date3 = Date2.add(14, "day");
          }

          tableData = { ...tableData, date: Date3.format("MM/DD/YY") };
        }

        const day3 = Date3.get("date");

        if (i === 1) {
          f_pay = emi / 2;
          tableData = { ...tableData, payment: formatNumber(f_pay) };
        } else if (
          day3 === 1 &&
          Date2.format("MM/DD/YY") === Date3.format("MM/DD/YY")
        ) {
          f_pay = emi / 2;
          tableData = { ...tableData, payment: formatNumber(f_pay) };
        } else if (
          day3 === 1 &&
          Date2.format("MM/DD/YY") !== Date3.format("MM/DD/YY")
        ) {
          f_pay = 0;
          tableData = { ...tableData, payment: formatNumber(f_pay) };
        } else {
          f_pay = emi / 2;
          tableData = { ...tableData, payment: formatNumber(f_pay) };
        }

        if (i === 1) {
          // if the payment for 1st week is less than the monthly payment
          if (f_pay < emi) {
            bal = f_pay;
            tableData = { ...tableData, balance: formatNumber(bal) };
          } else {
            bal = 0;
            tableData = { ...tableData, balance: formatNumber(bal) };
          }
        } else if (Date3 === "") {
          tableData = { ...tableData, balance: null };
        } else {
          if (Date3.format("MM/DD/YY") === "01/01/24")
            console.log(Date3.format("MM/DD/YY"), bal, pay_credit, f_pay);
          bal = bal + f_pay - pay_credit;
          tableData = { ...tableData, balance: formatNumber(bal) };

        }

        if (i === 1) {
          pay_credit = emi;
          tableData = { ...tableData, credit: formatNumber(pay_credit) };
        } else if (f_pay === 0 && Date3 === "") {
          tableData = { ...tableData, credit: null };
        } else if (f_pay === 0) {
          if (bal >= emi * 2) {
            pay_credit = emi * 2;
            tableData = { ...tableData, credit: formatNumber(pay_credit) };
          } else {
            pay_credit = emi; // exit;
            tableData = { ...tableData, credit: formatNumber(pay_credit) };
          }
        } else {
          pay_credit = 0;
          tableData = { ...tableData, credit: formatNumber(pay_credit) };
        }

        if (pay_credit > 0) {
          int = calculate_interest(bi_loan_amount, int_rate);
          tableData = { ...tableData, interest: formatNumber(int) };
        } else {
          int = 0;
          tableData = { ...tableData, interest: formatNumber(int) };
        }

        if (pay_credit > 0) {
          princ = pay_credit - int;
          tableData = { ...tableData, principal: formatNumber(princ) };
          ET_Accelerator_BW = ET_Accelerator_BW + 1;
        } else {
          princ = 0;
          tableData = { ...tableData, principal: formatNumber(princ) };
        }

        if (bi_loan_amount - princ <= 0.5) {
          bi_loan_amount = 0;
          tableData = {
            ...tableData,
            loanBalance: formatNumber(bi_loan_amount),
          };
        } else {
          bi_loan_amount = bi_loan_amount - princ;
          tableData = {
            ...tableData,
            loanBalance: formatNumber(bi_loan_amount),
          };
        }

        const temp = Date2;
        Date2 = Date3;
        Date3 = temp;
      }
      ET_Accelerator_BW_months = ET_Accelerator_BW;
      total_interest_BW = total_interest_BW + int;
      total_principal_BW = total_principal_BW + princ;
      table.push(tableData);
    }
    const total_amout_per_year_BW =
      ((total_principal_BW + total_interest_BW) / ET_Accelerator_BW_months) *
      12;

    setBiWeeklyAccelerationPlan({
      table: table,
      totalInterest: formatNumber(total_interest_BW),
      totalAmount: formatNumber(total_amout_per_year_BW),
      totalPrincipal: formatNumber(total_principal_BW),
      term: ET_Accelerator_BW_months,
    });
  };
  const calculatePrePayingPlan = (loan_amount, term_year, int_rate) => {
    const value = new Date();
    const term = term_year * 12;
    const monthlyPayment = calaculateEMI(int_rate / 1200, term_year * 12, loan_amount);
    const emi = formatNumber(monthlyPayment);

    let ET_AP_months = 0;
    // const table = [];
    const formattedLoanAmount = formatCurrency(Number(loan_amount))
    const table = [{ principal: '', interest: '', date: '', loanBalance: formattedLoanAmount }];

    let startDate = dayjs(value).add(1, 'month').startOf('month');

    let l_amount = loan_amount;
    let total_interest_AP = 0;
    let total_principal = 0;
    let total_add_principal = 0;
    let total_amout_per_year_AP = 0;
    let ET_AP = 0;

    for (let i = 1; i <= term; i++) {
      let tableData = {};
      const interest = calculate_interest(l_amount, int_rate);
      tableData = { ...tableData, interest: formatNumber(interest) };
      const principal = emi - interest;
      tableData = { ...tableData, principal: formatNumber(principal) };
      const add_principal = emi / 12;
      tableData = { ...tableData, additionalPrincipal: formatNumber(add_principal) };

      if (l_amount < 0.01) {
        break;
      } else {
        ET_AP = ET_AP + 1;
        if (i === 1) {
          tableData = { ...tableData, date: startDate.format('MM/DD/YYYY') };
        } else {
          startDate = startDate.add(1, 'month');
          tableData = { ...tableData, date: startDate.format('MM/DD/YYYY') };
        }

        l_amount = calculate_loan_balance_RAS_AP(l_amount, principal, add_principal);

        if (l_amount < 0.01) {
          tableData = { ...tableData, loanBalance: 0.0 };
        } else {
          tableData = { ...tableData, loanBalance: formatNumber(l_amount) };
        }

        ET_AP_months = ET_AP;
        total_interest_AP = total_interest_AP + interest;
        total_principal = total_principal + principal;
        total_add_principal = total_add_principal + add_principal;
        table.push(tableData);
      }
    }
    total_amout_per_year_AP =
      ((total_interest_AP + total_principal + total_add_principal) / ET_AP_months) * 12;
    setPrePayingPlan({
      table: table,
      totalInterest: formatNumber(total_interest_AP),
      totalAmount: formatNumber(total_amout_per_year_AP),
      term: ET_AP_months,
    });
  };

  useEffect(() => {
    if (content === "Standard")

      setDisplayContent(standardContent)



    if (content === "TrueBiweekly")

      setDisplayContent(trueweeklyContent)


    if (content === "Biweekly")

      setDisplayContent(biweeklyContent)

    if (content === "Prepaying")

      setDisplayContent(preContent)

  }, [content]);



  // const handleSubmit=()=>{

  // }
  const onSubmit = (data, e) => {


    // alert("data is",JSON.stringify(data))
    // console.log("data is",JSON.stringify(data))

    let json = data;
    // console.log(json)
    let term = json.Term;
    let interest = json.InterestRate;
    let loan_amount = json.LoanAmount;
    // console.log(term,interest,loan_amount)


    if (data !== null) {

      setTermYear(term);
      setInterestRate(interest);
      setLoanAmount(loan_amount);
      const emi = calaculateEMI(interest / 1200, term * 12, loan_amount);
      setMonthlyPayment(emi);

      calculateStandardMonthlyPlan(loan_amount, term, interest);
      calculateTrueBiWeeklylyPlan(loan_amount, term, interest);
      calculateBiWeeklyAccelerationPlan(loan_amount, term, interest);
      calculatePrePayingPlan(loan_amount, term, interest);
    }


  }
  const { register, handleSubmit, formState: { errors }, getValues, reset } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(Schema),
  });

  let standardContent, trueweeklyContent, biweeklyContent, preContent;


  {
    if (standardMonthlyPlan) {

      standardContent = (
        <div>
          <h6 style={{ textAlign: 'center', fontWeight: 'bold' }}>Standard Monthly Plan</h6>
          <Table className={styles.noPaddingTable} responsive style={{ textAlign: 'center' }}>

            <tr>
              <td><strong>Month</strong></td>
              <td><strong>Date</strong></td>
              <td><strong>Interest</strong></td>
              <td><strong>Principal</strong></td>
              <td><strong>Loan Balance</strong></td>
            </tr>

            {standardMonthlyPlan.table.map((el, id) => (

              <tr>
                <td>{id === 0 ? null : id}</td>
                <td>{el.date}</td>
                <td>{formatCurrency(el.interest)}</td>
                <td>{formatCurrency(el.principal)}</td>
                <td>{formatCurrency(el.loanBalance)}</td>
              </tr>

            ))}



            <tr>
              <td><b>Grand Total</b></td>
              <td></td>
              <td><b>{formatCurrency(standardMonthlyPlan.totalInterest)}</b></td>
              <td><b>{formatCurrency(standardMonthlyPlan.totalPrincipal)}</b></td>
              <td><b>$0.00</b></td>
            </tr>





          </Table></div>)




    }

    else standardContent = null
  }



  {
    if (trueBiWeeklylyPlan) {

      trueweeklyContent = (

        <div>

          <h6 style={{ textAlign: 'center', fontWeight: 'bold' }}>True Biweekly Plan</h6>
          <Table className={styles.noPaddingTable} responsive style={{ textAlign: 'center' }}>



            <tr>
              <td><strong>Biweekly Due Date</strong></td>
              <td><strong>Payment</strong></td>
              <td><strong>Interest</strong></td>
              <td><strong>Principal</strong></td>
              <td><strong>Loan Balance</strong></td>
            </tr>

            {trueBiWeeklylyPlan.table.map((el, id) => (
              <tr>
                <td>{el.date}</td>
                <td>{formatCurrency(el.payment)}</td>
                <td>{formatCurrency(el.interest)}</td>
                <td>{formatCurrency(el.principal)}</td>
                <td>{formatCurrency(el.loanBalance)}</td>


              </tr>
            ))}

            <tr>
              <td><b>Grand Total</b></td>
              <td></td>
              <td><b>{formatCurrency(trueBiWeeklylyPlan.totalInterest)}</b></td>
              <td><b>{formatCurrency(trueBiWeeklylyPlan.totalPrincipal)}</b></td>
              <td><b>$0.00</b></td>
            </tr>





          </Table></div>)

    }

    else trueweeklyContent = null
  }

  {
    if (biWeeklyAccelerationPlan) {

      biweeklyContent = (<div>
        <h6 style={{ textAlign: 'center', fontWeight: 'bold' }}>Biweekly Acceleration Plan</h6>
        <Table className={styles.noPaddingTable} responsive style={{textAlign: 'center'}}>

          <tr>
            <td><strong>Biweekly Due Date</strong></td>
            <td><strong>Payment	</strong></td>
            <td><strong>Balance in Special Biweekly Account</strong></td>
            <td><strong>Payment Credited to Mortgage</strong></td>
            <td><strong>Interest</strong></td>
            <td><strong>Principal</strong></td>
            <td><strong>Loan Balance</strong></td>
          </tr>

          {biWeeklyAccelerationPlan.table.map((el, id) => (
            <tr>
              <td>{el.date}</td>
              <td>{formatCurrency(el.payment)}</td>
              <td>{formatCurrency(el.balance)}</td>
              <td>{formatCurrency(el.credit)}</td>
              <td>{formatCurrency(el.interest)}</td>
              <td>{formatCurrency(el.principal)}</td>
              <td>{formatCurrency(el.loanBalance)}</td>


            </tr>
          ))}

          <tr>
            <td><b>Grand Total</b></td>
            <td></td>
            <td></td>
            <td></td>
            <td><b>{formatCurrency(biWeeklyAccelerationPlan.totalInterest)}</b></td>
            <td><b>{formatCurrency(biWeeklyAccelerationPlan.totalPrincipal)}</b></td>
            <td><b>$0.00</b></td>
          </tr>





        </Table></div>)

    }

    else biweeklyContent = null
  }


  {
    if (prePayingPlan) {

      preContent = (<div>
        <h6 style={{ textAlign: 'center', fontWeight: 'bold' }}>Pre Paying 1/2th Of Payment Every Month</h6>
        <Table className={styles.noPaddingTable} responsive style={{ textAlign: 'center' }}>

          <tr>
            <td><strong>Month</strong></td>
            <td><strong>Date</strong></td>
            <td><strong>Interest</strong></td>
            <td><strong>Principal</strong></td>
            <td><strong>Additional  Principal</strong></td>
            <td><strong>Loan Balance</strong></td>
          </tr>

          {prePayingPlan.table.map((el, id) => (
            <tr>
              <td>{id === 0 ? "Loan 1" : "Payment" + "" + id}</td>
              <td>{el.date}</td>
              <td>{formatCurrency(el.interest)}</td>
              <td>{formatCurrency(el.principal)}</td>
              <td>{formatCurrency(el.additionalPrincipal)}</td>
              <td>{formatCurrency(el.loanBalance)}</td>
            </tr>
          ))}





        </Table></div>)

    }

    else preContent = null
  }



  const handleShowStandard = () => {

    setContent("Standard")

  }

  const handleShowtTrueBi = () => {
    setContent("TrueBiweekly")

  }

  const handleShowBiweek = () => {

    setContent("Biweekly")

  }

  const handleShowPrepay = () => {

    setContent("Prepaying")

  }

  const handleDisclaimerShow = () => {

    setDisclaimer(!(disclaimer))

  }



  return (
    <article>
      <Helmet>
        <title>Biweekly Calculator | LowRates</title>
        <meta name="description" content="LowRates, Mortgage Loans" />
        <meta name="keywords" content="LowRates, Mortgage, Loans, Swmc, SunWestMortgage, Mortgage Possible" />

      </Helmet>
      <div>
        <img
          src={imgCDNURL + "loanprogrambannerimage.webp"}
          className={styles.loanprogrambannerimage}

        />

        <img
          src={imgCDNURL + "loanprogrambannerimagemobile.webp"}
          className={styles.loanprogrambannerimagemobile}

        />
        <a href="https://seemyloanstatus.com/ApplyNow/?c=lowrates" target="_blank" title="Click Here To ApplyNow">
          <img
            src={imgCDNURL + "applynowbanner.webp"}
            className={styles.applynowbanner}
          />
        </a>
        <div className={styles.reficalculatorStyleDiv}>
          <StyleWrapper>
            <Container fluid className={styles.pageContainer}>

              <Row className={styles.header}>

                <h1 style={{ marginBottom: '20px', textAlign: 'center' }}>Biweekly Payment Calculator</h1>
                <h2>Simple, Fast Service.</h2>
              </Row>


              <Container className={styles.calculatorContainer}>
                <center>
                  <Forms
                    handleSubmit={handleSubmit}
                    buttonvalue="Calculate"
                    onSubmit={onSubmit}>


                    <Inputs
                      register={register("LoanAmount")}
                      errors={errors}
                      type="text"
                      name="LoanAmount"
                      label="Loan Amount:"
                    />

                    <Inputs
                      register={register("Term")}
                      errors={errors}
                      type="number"
                      name="Term"
                      label="Term (In Years):"
                    />

                    <Inputs
                      register={register("InterestRate")}
                      errors={errors}
                      type="text"
                      name="InterestRate"
                      label="Interest Rate:"
                    />

                  </Forms>
                </center>
                <Table responsive>

                  <thead>
                    <th colSpan={3} style={{ textAlign: 'center', background: 'rgb(33, 154, 213)', color: '#fff' }}>Financial Details</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Loan Amount</td>
                      <td>
                        {formatCurrency(Number(loanAmount))}</td>
                    </tr>
                    <tr>
                      <td>Terms (In Years)</td>
                      <td>
                        {termYear}</td>
                    </tr>
                    <tr>
                      <td>Interest Rate</td>
                      <td>
                        {interestRate ? interestRate : "0.00"}%
                      </td>
                    </tr>
                    <tr>
                      <td>Monthly Payment</td>
                      <td>
                        {monthlyPayment ? formatCurrency(monthlyPayment) : "$0.00"}
                      </td>
                    </tr>
                  </tbody>
                </Table>


                <Table responsive>
                  <thead>
                    <th style={{ textAlign: 'center', background: 'rgb(33, 154, 213)', color: '#fff' }} colSpan={5}>Financial Details</th>
                    <tr>
                      <th></th>
                      <th >STANDARD MONTHLY PLAN</th>
                      <th >TRUE BIWEEKLY PLAN</th>
                      <th >BIWEEKLY ACCELERATION PLAN</th>
                      <th >PRE PAYING 1/12th OF PAYMENT EVERY MONTH</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Offered By</td>
                      <td>Most Mortgage Banks</td>
                      <td>Select Mortgage Banks</td>
                      <td>Third Party Companies</td>
                      <td>Most Mortgage Banks</td>
                    </tr>
                    <tr>
                      <td>Total Interest Paid During Life Of Mortgage</td>
                      <td><span id="total_interest_RAS">{standardMonthlyPlan ? formatCurrency(standardMonthlyPlan.totalInterest) : "$0.00"}</span></td>
                      <td><span id="total_interest_True_BW">{trueBiWeeklylyPlan ? formatCurrency(trueBiWeeklylyPlan.totalInterest) : "$0.00"}</span></td>
                      <td><span id="total_interest_BW">{biWeeklyAccelerationPlan ? formatCurrency(biWeeklyAccelerationPlan.totalInterest) : "$0.00"}</span></td>
                      <td><span id="total_interest_AP">{prePayingPlan ? formatCurrency(prePayingPlan.totalInterest) : "$0.00"}</span></td>
                    </tr>
                    <tr>
                      <td>Payment Amount Per Year</td>
                      <td><span id="total_amout_per_year_RAS">{standardMonthlyPlan ? formatCurrency(standardMonthlyPlan.totalAmount) : "$0.00"}</span></td>
                      <td><span id="total_amout_per_year_true_BW">{trueBiWeeklylyPlan ? formatCurrency(trueBiWeeklylyPlan.totalAmount) : "$0.00"}</span></td>
                      <td><span id="total_amout_per_year_BW">{biWeeklyAccelerationPlan ? formatCurrency(biWeeklyAccelerationPlan.totalAmount) : "$0.00"}</span></td>
                      <td><span id="total_amout_per_year_AP">{prePayingPlan ? formatCurrency(prePayingPlan.totalAmount) : "$0.00"}</span></td>
                    </tr>
                    <tr>
                      <td>Effective Term (In Months)</td>
                      <td><span id="total_months_RAS">{standardMonthlyPlan ? standardMonthlyPlan.term : null}</span></td>
                      <td><span id="total_months_True_BW">{trueBiWeeklylyPlan ? trueBiWeeklylyPlan.term : null}</span></td>
                      <td><span id="total_months_BAS_AP">{biWeeklyAccelerationPlan ? biWeeklyAccelerationPlan.term : null}</span></td>
                      <td><span id="total_months_RAS_AP">{prePayingPlan ? prePayingPlan.term : null}</span></td>
                    </tr>
                    <tr>

                      <td>Links to Amortization Schedule</td>
                      <td><span onClick={handleShowStandard} style={{ cursor: 'pointer' }}><u>Click Here</u></span></td>
                      <td><span onClick={handleShowtTrueBi} style={{ cursor: 'pointer' }}><u>Click Here</u></span></td>
                      <td><span onClick={handleShowBiweek} style={{ cursor: 'pointer' }}> <u>Click Here</u></span></td>
                      <td><span onClick={handleShowPrepay} style={{ cursor: 'pointer' }}><u>Click Here</u></span></td>
                    </tr>
                  </tbody>
                </Table>




                {displayContent ?

                  displayContent
                  : null}

                <h6 style={{ textDecoration: 'underline', color: 'blue', textAlign: 'center', cursor: 'pointer' }} onClick={handleDisclaimerShow}>Calculator Disclaimer</h6>


                {disclaimer ?

                  <p style={{ fontSize: '14px' }}>The results represented are only generalized estimates and are solely intended for illustrative purposes only. The estimates may differ from one Bi-weekly program to another, depending on current program guidelines, and actual individual results may vary depending on individual situation. Please consult your mortgage professional for more information.</p>

                  : null
                }



              </Container>



            </Container>

          </StyleWrapper>
        </div>
      </div>
    </article>
  );
}





export default BiweeklyCalculator
