import React, { useEffect, useRef, useState } from 'react';
import Classes from '../../containers/LoanPrograms/index.module.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { NavLink, Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import BlueButton from '../../components/BlueButton';
import ResourcesMenu from '../../components/ResourcesMenu';
import HowItWorks from '../../components/HowItWorks';
import WhiteButton from '../../components/WhiteButton';
import { Helmet } from 'react-helmet';


const NavWrapper = styled.div`
.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    color: #00AAE4;
    background-color: transparent;
    // border-bottom:1px solid #3074b7;
    padding: 0.8rem 1rem;
    border-radius: 0!important;

}
.nav-link {
    display: block;
    font-size: 20.1px;
    font-weight: 800;
    padding: 0.8rem 1rem;
    color: #083F88;
    border-radius: 0!important;
}

@media(min-width: 1200px)and (max-width:1400px) {
    .nav-link {
        font-size: 18.1px;
    }
}

@media(min-width: 992px)and (max-width:1199.7px) {
    .nav-link {
        font-size: 15.1px;
        font-weight: 900;
    }
}

@media(min-width: 768px)and (max-width:991.7px) {
    .nav-link {
        font-size: 12.7px;
        padding: 0.5rem 0.5rem;
        font-weight: 900;
    }
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
        padding: 0.5rem 0.5rem;
    }
}

@media(max-width:767px){
    .nav-link {
        font-size: 15.5px;
        padding: 0.5rem 0.5rem;
    }
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
        padding: 0.5rem 0.5rem;
    }
}
`

const routeKeys = {
    conventional: "conventional",
    fha: "fha",
    investment: "investment",
    jumbo: "jumbo",
    renovation: "renovation",
    refinancing: "refinancing",
    reverse: "reverse",
    va: "va",
    mortgageprocess: "mortgageprocess"
}

const LoanPrograms = ({ history }) => {
    const [key, setKey] = useState('mortgageprocess');
    const imgCDNURL = process.env.REACT_APP_LOWRATES_RESOURCES;
    const [navItemClicked, setNavItemClicked] = useState(false);
    let { eventKey } = useParams();
    const mainContentRef = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleNavItemClick = (selectedKey) => {
        setKey(selectedKey);
        setNavItemClicked(true);
        if (mainContentRef.current) {
            window.scrollTo({
                top: mainContentRef.current.offsetTop,
                behavior: "smooth"
            });
        }
    };

    return (
        <article style={{ backgroundColor: '#FCFCFC' }}>

            <Helmet>
                <title>Mortgage Process | LowRates</title>
                <meta name="description" content="LowRates, Mortgage Loans" />
                <meta name="keywords" content="LowRates, Mortgage, Loans, Swmc, SunWestMortgage, Mortgage Possible" />
            </Helmet>

            <div className={Classes.loanProgramDiv}>

                <img
                    src={imgCDNURL + "loanprogrambannerimage.webp"}
                    className={Classes.loanprogrambannerimage}

                />

                <img
                    src={imgCDNURL + "loanprogrambannerimagemobile.webp"}
                    className={Classes.loanprogrambannerimagemobile}

                />

                <Container className={Classes.loanProgramContainer}>
                    <a href="https://seemyloanstatus.com/ApplyNow/?c=lowrates" target="_blank" title="Click Here To ApplyNow">
                        <img
                            src={imgCDNURL + "applynowbanner.webp"}
                            className={Classes.applynowbanner}
                        />
                    </a>
                    <NavWrapper>
                        <Tab.Container id="left-tabs-example" activeKey={key}
                            onSelect={handleNavItemClick}>
                            <Row>
                                <Col md={12} lg={12} sm={12} className={Classes.fixedLeft}>
                                    <h4 className={Classes.leftHeader}>Loan Services</h4>
                                    <Row>
                                        <Col md={6} lg={6} sm={12} >
                                            <Nav variant="pills" className={Classes.setNavLeft}>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.conventional} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
                                                        if (e.ctrlKey) {
                                                            window.open("/conventional-loans", "_blank");
                                                        } else {
                                                            handleNavItemClick(routeKeys.conventional);
                                                        }
                                                    }} to="/conventional-loans">CONVENTIONAL LOANS</Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.fha} as={NavLink} to="/fha-loans" onClick={(e) => {
                                                        if (e.ctrlKey) {
                                                            window.open("/fha-loans", "_blank");
                                                        } else {
                                                            handleNavItemClick(routeKeys.fha);
                                                        }
                                                    }} activeClassName={Classes.activePillLink} className={Classes.pillLink}>FHA LOANS</Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.investment} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
                                                        if (e.ctrlKey) {
                                                            window.open("/investment-loans", "_blank");
                                                        } else {
                                                            handleNavItemClick(routeKeys.investment);
                                                        }
                                                    }} to="/investment-loans">INVESTMENT PROPERTIES</Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.jumbo} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink1} onClick={(e) => {
                                                        if (e.ctrlKey) {
                                                            window.open("/jumbo-loan", "_blank");
                                                        } else {
                                                            handleNavItemClick(routeKeys.jumbo);
                                                        }
                                                    }} to="/jumbo-loans">JUMBO LOANS</Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                        <Col md={6} lg={6} sm={12} >
                                            <Nav variant="pills" className={Classes.setNavLeft}>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.renovation} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
                                                        if (e.ctrlKey) {
                                                            window.open("/renovation-loans", "_blank");
                                                        } else {
                                                            handleNavItemClick(routeKeys.renovation);
                                                        }
                                                    }} to="/renovation-loans">RENOVATION AND CONSTRUCTION LOANS</Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.refinancing} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
                                                        if (e.ctrlKey) {
                                                            window.open("/refinance", "_blank");
                                                        } else {
                                                            handleNavItemClick(routeKeys.refinancing);
                                                        }
                                                    }} to="/refinance">REFINANCING</Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.reverse} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
                                                        if (e.ctrlKey) {
                                                            window.open("/reverse-mortgage", "_blank");
                                                        } else {
                                                            handleNavItemClick(routeKeys.reverse);
                                                        }
                                                    }} to="/reverse-mortgage">REVERSE MORTGAGE</Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.va} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink2} onClick={(e) => {
                                                        if (e.ctrlKey) {
                                                            window.open("/va-loans", "_blank");
                                                        } else {
                                                            handleNavItemClick(routeKeys.va);
                                                        }
                                                    }} to="/va-loans">VA LOANS</Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </NavWrapper>
                </Container>

                <div className={Classes.loanProgramContentDiv} ref={mainContentRef}>

                    <Container className={Classes.loanProgramContainer}>
                        <NavWrapper>
                            <Tab.Container id="left-tabs-example" activeKey={key}
                                onSelect={(k) => setKey(k)}>
                                <Row>

                                    <Col sm={12} lg={12} md={12} className={Classes.rightCol}>
                                        <h1 className={Classes.loanHeader}>
                                            {key === routeKeys.conventional && 'CONVENTIONAL LOANS'}
                                            {key === routeKeys.fha && 'FHA LOANS'}
                                            {key === routeKeys.investment && 'INVESTMENT PROPERTIES LOANS'}
                                            {key === routeKeys.jumbo && 'JUMBO LOANS'}
                                            {key === routeKeys.renovation && 'RENOVATION & CONSTRUCTION'}
                                            {key === routeKeys.refinancing && 'REFINANCING'}
                                            {key === routeKeys.reverse && 'REVERSE MORTGAGE / HECM LOANS'}
                                            {key === routeKeys.va && 'VA LOANS'}
                                            {key === routeKeys.mortgageprocess && 'OUR MORTGAGE PROCESS'}
                                        </h1>

                                        <div className={Classes.conventional}>


                                            <Tab.Pane eventKey={routeKeys.mortgageprocess}>
                                                <img
                                                    img src="https://lowrates-staging.s3.amazonaws.com/images/process.jpg" alt="mortgageprocess image"
                                                    className={Classes.loanpurposemortgageprocessimg}
                                                />
                                                <p>
                                                    At Lowrates, we want to educate our clients on the mortgage process in as clear and efficient way as possible. To prevent leaving any of our clients in the dark, we created a detailed list to explain how the mortgage loan process works with LowRates.
                                                </p>
                                                <h3>1. Start Your Application</h3>
                                                <p>
                                                    Our team offers several ways to apply for a new home loan. Use our online application to send in your request or contact our office via phone or email. We’ll match you up with one of our team members who will get you started on the mortgage loan process. Prepare small details like your mortgage loan goals, household income, and total assets to share with our team and we’ll prepare your options.
                                                </p>
                                                <h3>2. Review Your Options with a Member of Our Team</h3>
                                                <p>
                                                    Once your application is complete, we’ll take a look at your family’s financial situation and home loan needs and pair you with a list of home loans that fits the cost, rate, and benefits you’ll need. </p>
                                                <h3>3. Confirm Your Information</h3>
                                                <p>
                                                    A member of our team will review your financial information and confirm that everything looks good. Once everything is confirmed, we will move forward with the application.
                                                </p>
                                                <h3>4. Close on Your New Loan</h3>
                                                <p>
                                                    Once your application is accepted, you are ready to close your loan. Whether you’re ready that same day or need extra time, our loan officers will always be available to you whenever you choose to close on your new loan.
                                                </p>
                                                <h3>5. Continue Working with Our Team Throughout the Lifetime of Your Loan</h3>
                                                <p>
                                                    After you close on your loan, you’ll have full access to our team and resources throughout the lifespan of your loan, like easy access to your loan information and paperwork as well as mortgage advice from our officers. If your financial situation ever changes, feel free to update your payment schedule to better accommodate your new finances. Our team is proud to offer continued service to our clients, from their first loan application to the very last mortgage payment.
                                                </p>
                                            </Tab.Pane>


                                        </div>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </NavWrapper>

                    </Container>
                </div>
            </div>

            <div className={Classes.loanhowitworksDiv}>

                <Container className={Classes.loanhowitworksContainer}>
                    <HowItWorks />
                </Container>
            </div>

            <div className={Classes.loanresourcesDiv}>

                <Container className={Classes.loanresourcesContainer}>
                    <ResourcesMenu />
                </Container>
            </div>
        </article>
    )
}

export default LoanPrograms;