import React, { useEffect } from 'react';
import { Container, Col, Row, Table } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import styles from './index.module.css'
import styled from 'styled-components'

const StyleWrapper = styled.div`
.tablebg {
    background: #e5e5e5 !important;
}
`

const RequiredDocs = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <article>
            {/* <Helmet>

                <title>Documents | LowRates</title>
                <meta name="description" content="LowRates, Mortgage Loans" />
                <meta name="keywords" content="LowRates, Mortgage, Loans, Swmc, SunWestMortgage, Mortgage Possible" />

            </Helmet> */}
            <StyleWrapper>
                <div className={styles.title}>
                    <h1>Documents Needed</h1>
                </div>
                {/* <Container className={styles.containerdocument}> */}


                    <Table responsive className="table table-bordered">
                        <thead >

                            <tr className={styles.tableBody}>
                                <th className={styles.tableHead}>Documents</th>
                                <th className={`${styles.tableHead1} ${styles.commonCell}`}>Purchase</th><th className={`${styles.tableHead1} ${styles.commonCell}`}>Refinance</th>
                            </tr>
                        </thead>
                        <tbody className={styles.tableBody}>
                            <tr className="tablebg">
                                <td align="left" colspan="3" className={styles.maindocumentheading}><b >Identification Documents</b></td></tr>
                            <tr className="bg-white">
                                <td align="left" style={{ color: 'rgba(8, 63, 136, 1)' }}>Driver's License</td>
                                <td align="center" rowspan="2" className={`align-middle ${styles.commonCell}`} style={{ color: 'rgba(8, 63, 136, 1)' }}><b >Yes</b></td><td align="center" rowspan="2" className={`align-middle ${styles.commonCell}`} style={{ color: 'rgba(8, 63, 136, 1)' }}><b >Yes</b></td></tr>
                            <tr className="bg-white">
                                <td align="left" style={{ color: 'rgba(8, 63, 136, 1)' }}>Social Security Card</td></tr>
                        </tbody>
                    </Table>
                {/* </Container> */}
            </StyleWrapper>


            <StyleWrapper>
                {/* <Container> */}
                    <Table responsive className="table table-bordered">
                        <tbody className={styles.tableBody}>

                            <tr className="tablebg"><td align="left" colspan="3" className={styles.maindocumentheading}><b >Income Documents</b></td></tr>
                            <tr >
                                <td align="left" colspan="3" className={styles.subdocumentheading} style={{ color: 'rgba(8, 63, 136, 1)' }}><b >Salaried</b></td></tr>
                            <tr className="bg-white">
                                <td align="left" style={{ color: 'rgba(8, 63, 136, 1)' }}>Pay Stubs for 2 recent pay periods</td>
                                <td align="center" rowspan="3" className={`align-middle ${styles.commonCell}`} style={{ color: 'rgba(8, 63, 136, 1)' }}><b >Yes</b></td>
                                <td align="center" rowspan="3" className={`align-middle ${styles.commonCell}`} style={{ color: 'rgba(8, 63, 136, 1)' }}><b >Yes*</b></td></tr>
                            <tr className="bg-white">
                                <td align="left" style={{ color: 'rgba(8, 63, 136, 1)' }}>Last two years - W2 forms, 1099's</td></tr>
                            <tr className="bg-white">
                                <td align="left" style={{ color: 'rgba(8, 63, 136, 1)' }}>Last two years - 1040 (Personal Federal Tax Returns) with all supporting schedules.</td></tr>
                        </tbody>
                    </Table>
                {/* </Container> */}
            </StyleWrapper>


            <StyleWrapper>
                {/* <Container> */}
                    <Table responsive className="table table-bordered">
                        <tbody className={styles.tableBody}>
                            <tr ><td align="left" colspan="3" className={styles.subdocumentheading} style={{ color: 'rgba(8, 63, 136, 1)' }}><b >Self Employed</b></td></tr>
                            <tr cla ss="bg-white">
                                <td align="left" style={{ color: 'rgba(8, 63, 136, 1)' }}>Current year's Year to date Profit and Loss Statement to current month</td>
                                <td align="center" rowspan="3" className={`align-middle ${styles.commonCell}`} style={{ color: 'rgba(8, 63, 136, 1)' }}><b >Yes</b></td>
                                <td align="center" rowspan="3" className={`align-middle ${styles.commonCell}`} style={{ color: 'rgba(8, 63, 136, 1)' }}><b >Yes*</b></td></tr>
                            <tr className="bg-white">
                                <td align="left" style={{ color: 'rgba(8, 63, 136, 1)' }}>Last two years - Form 1120S / Form 1065 / Form 1120 Federal Corporate tax returns only with all supporting schedules</td></tr>
                            <tr className="bg-white">
                                <td align="left" style={{ color: 'rgba(8, 63, 136, 1)' }}>Last two years – K-1's with all supporting schedules</td></tr>

                        </tbody>
                    </Table>
                {/* </Container> */}
            </StyleWrapper>


            <StyleWrapper>
                {/* <Container> */}
                    <Table responsive className="table table-bordered">
                        <tbody className={styles.tableBody}>
                            <tr >
                                <td align="left" colspan="3" className={styles.subdocumentheading} style={{ color: 'rgba(8, 63, 136, 1)' }}><b >Other Income</b></td></tr>
                            <tr >
                                <td align="left" style={{ color: 'rgba(8, 63, 136, 1)' }}>Retirement Benefit Award letters with proof of 3 years continuance</td>
                                <td align="center" rowspan="4" className={`align-middle ${styles.commonCell}`} style={{ color: 'rgba(8, 63, 136, 1)' }}><b >Yes</b></td>
                                <td align="center" rowspan="4" className={`align-middle ${styles.commonCell}`} style={{ color: 'rgba(8, 63, 136, 1)' }}><b >Yes*</b></td></tr>
                            <tr ><td align="left" style={{ color: 'rgba(8, 63, 136, 1)' }}>Social Security Award Letter</td></tr>
                            <tr className="bg-white">
                                <td align="left" style={{ color: 'rgba(8, 63, 136, 1)' }}>Disability Award Letter</td></tr>
                            <tr >
                                <td align="left" style={{ color: 'rgba(8, 63, 136, 1)' }}>Divorce Decree, Child Support Order &amp; DES received payment history</td></tr>

                        </tbody>
                    </Table>
                {/* </Container> */}
            </StyleWrapper>


            <StyleWrapper>
                {/* <Container> */}
                    <Table responsive className="table table-bordered">
                        <tbody className={styles.tableBody}>
                            <tr >
                                <td align="left" colspan="3" className={styles.subdocumentheading} style={{ color: 'rgba(8, 63, 136, 1)' }}><b >Asset Documents</b></td></tr>
                            <tr >
                                <td align="left" style={{ color: 'rgba(8, 63, 136, 1)' }}>Checking / Saving Bank Account Statements: Bank statements for recent two consecutive months</td>
                                <td align="center" className={`align-middle ${styles.commonCell}`} style={{ color: 'rgba(8, 63, 136, 1)' }}><b >Yes</b></td>
                                <td align="center" className={`align-middle ${styles.commonCell}`} style={{ color: 'rgba(8, 63, 136, 1)' }}><b >Yes*</b></td></tr>
                            <tr >
                                <td style={{ color: 'rgba(8, 63, 136, 1)' }}>Gift Funds: <br />
                                    <ul >
                                        <li className={styles.tableli}>Completely filled Gift letter signed by the donor.</li>
                                        <li className={styles.tableli}>Donor's bank statement</li>
                                    </ul></td>
                                <td align="center" className={`align-middle ${styles.commonCell}`} style={{ color: 'rgba(8, 63, 136, 1)' }}><b >Yes</b></td>
                                <td align="center" className={`align-middle ${styles.commonCell}`} style={{ color: 'rgba(8, 63, 136, 1)' }}><b >No</b></td>
                            </tr>

                            <tr >
                                <td style={{ color: 'rgba(8, 63, 136, 1)' }}>Retirement Accounts / Stocks and Bonds<br />
                                    <ul >
                                        <li className={styles.tableli}>Recent statement covering minimum of 60 days</li>
                                        <li className={styles.tableli}>Terms and Conditions of Withdrawal confirming sufficient access to withdraw funds whenever required</li>
                                    </ul></td>
                                <td align="center" className={`align-middle ${styles.commonCell}`} style={{ color: 'rgba(8, 63, 136, 1)' }}><b >Yes</b></td>
                                <td align="center" className={`align-middle ${styles.commonCell}`} style={{ color: 'rgba(8, 63, 136, 1)' }}><b >Yes*</b></td>
                            </tr>


                        </tbody>
                    </Table>
                {/* </Container> */}
            </StyleWrapper>


            <StyleWrapper>
                {/* <Container> */}
                    <Table responsive className="table table-bordered">
                        <tbody className={styles.tableBody}>
                            <tr className="tablebg">
                                <td align="left" colspan="3" className={styles.maindocumentheading}><b >Transaction Specific Documents</b></td></tr>
                            <tr >
                                <td align="left" colspan="3" className={styles.subdocumentheading} style={{ color: 'rgba(8, 63, 136, 1)' }}><b >Purchase</b></td></tr>
                            <tr >
                                <td style={{ color: 'rgba(8, 63, 136, 1)' }}>Purchase / Sales Contract or Offer to Purchase with all Addenda (Attachments and Enclosures) </td>
                                <td align="center" rowspan="2" className={`align-middle ${styles.commonCell}`} style={{ color: 'rgba(8, 63, 136, 1)' }}><b >Yes</b></td>
                                <td align="center" rowspan="2" className={`align-middle ${styles.commonCell}`} style={{ color: 'rgba(8, 63, 136, 1)' }}><b >No</b></td></tr>
                            <tr >
                                <td style={{ color: 'rgba(8, 63, 136, 1)' }}>Copy of the Earnest Money Check and Receipt from the Title / Escrow company </td></tr>
                            <tr >
                                <td align="left" style={{ color: 'rgba(8, 63, 136, 1)' }}>Mortgage statements for mortgages associated with other properties owned</td>
                                <td align="center" className={`align-middle ${styles.commonCell}`} style={{ color: 'rgba(8, 63, 136, 1)' }}><b >Yes</b></td>
                                <td align="center" className={`align-middle ${styles.commonCell}`} style={{ color: 'rgba(8, 63, 136, 1)' }}><b >Yes*</b></td></tr>
                            <tr ><td align="left" colspan="3" className={styles.subdocumentheading} style={{ color: 'rgba(8, 63, 136, 1)' }}><b >Refinance</b></td></tr>
                            <tr >
                                <td align="left" style={{ color: 'rgba(8, 63, 136, 1)' }}>Mortgage statements for mortgages associated with other properties owned</td>
                                <td align="center" className={`align-middle ${styles.commonCell}`} style={{ color: 'rgba(8, 63, 136, 1)' }}><b >Yes</b></td>
                                <td align="center" className={`align-middle ${styles.commonCell}`} style={{ color: 'rgba(8, 63, 136, 1)' }}><b >Yes*</b></td></tr>
                            <tr >
                                <td align="left" style={{ color: 'rgba(8, 63, 136, 1)' }}>Current Mortgage Statement(s)</td>
                                <td align="center" rowspan="4" className={`align-middle ${styles.commonCell}`} style={{ color: 'rgba(8, 63, 136, 1)' }}><b >No</b></td>
                                <td align="center" rowspan="4" className={`align-middle ${styles.commonCell}`} style={{ color: 'rgba(8, 63, 136, 1)' }}><b >Yes*</b></td></tr>
                            <tr >
                                <td align="left" style={{ color: 'rgba(8, 63, 136, 1)' }}>Homeowner's Insurance Declaration page reflecting annual premium</td></tr>
                            <tr className="bg-white">
                                <td align="left" style={{ color: 'rgba(8, 63, 136, 1)' }}>Tax Bill / Tax Certificate</td></tr>
                            <tr >
                                <td align="left" style={{ color: 'rgba(8, 63, 136, 1)' }}>Current Homeowners' Association (HOA) bill</td>
                            </tr>
                        </tbody>
                    </Table >
                    <p style={{ color: '#083f88', paddingLeft: '10px' }}>*Not required, in case of a streamline refinance program.</p>
                {/* </Container > */}
            </StyleWrapper >
        </article >
    );
}

export default RequiredDocs;