import { Container, Col, Row } from 'react-bootstrap';

export const Definitions = ({ styles }) => (
    <Container className={styles.share_section} style={{
        padding: '0'
    }}>
        <Col className={styles.share_section_col}>
            <Row className={
                `${styles.share_section_row} ${styles.blue_row} ${styles.share_section_strong}`
            }>
                <Col className={styles.share_section_row_desc}>
                    <h2>
                        Definitions
                    </h2>
                </Col>
            </Row>
            <Row className={
                `${styles.facts_section_row} ${styles.light_gray}`
            }>
                <Col lg={2} sm={3} xs={4} className={styles.facts_section_row_heading}>
                    <div>Affiliates</div>
                </Col>
                <Col className={styles.facts_section_row_desc}>
                    <div>
                        <p>
                            Companies related by common ownership or control. They can be financial and&nbsp;
                            nonfinancial companies.
                        </p>
                        <p style={{ fontStyle: 'italic' }}>
                            Our affiliates include companies with an XL Dynamics name; Sun West Financial&nbsp;
                            Services International, Inc., a company that provides technology and back office&nbsp;
                            support to mortgage and commercial banking and lending industry; PAG Financial&nbsp;
                            International, LLC, a company that provides consulting services; Marco Management&nbsp;
                            LLC, a company that provides personal investment rental property management; Sun West&nbsp;
                            Property Investments LLC, a company that provides personal investment&nbsp;
                            rental property management; XLD Insurance Company, Inc., a commercial captive insurance&nbsp;
                            company; XLD Brokerage Agency, LLC, a commercial insurance brokerage service company; XLD&nbsp;
                            International Insurer Corporation, a commercial captive insurance company; ApexLend, LLC, a&nbsp;
                            company that provides consumer lending services.
                        </p>
                    </div>
                </Col>
            </Row>
            <Row className={
                `${styles.facts_section_row} ${styles.gray_row}`
            }>
                <Col lg={2} sm={3} xs={4} className={styles.facts_section_row_heading}>
                    <span>Non-affiliates</span>
                </Col>
                <Col className={styles.facts_section_row_desc}>
                    <div>
                        <p>
                            Companies not related by common ownership or control. They can be financial and nonfinancial companies.
                        </p>
                        <p style={{ fontStyle: 'italic' }}>
                            Non-affiliates we share with can include financial services companies such as mortgage brokers, lenders, and Servicers.
                        </p>
                    </div>
                </Col>
            </Row>
            <Row className={
                `${styles.facts_section_row} ${styles.light_gray} ${styles.border_bottom_none}`
            }>
                <Col lg={2} sm={3} xs={4} className={styles.facts_section_row_heading}>
                    <span>Why can’t I limit all sharing?</span>
                </Col>
                <Col className={styles.facts_section_row_desc}>
                    <div>
                        <p>
                            A formal agreement between non-affiliated financial companies that together market financial products or services to you.
                        </p>
                        <p style={{ fontStyle: 'italic' }}>
                            Our joint marketing partners can include financial services companies such as mortgage brokers, lenders, and Servicers.
                        </p>
                    </div>
                </Col>
            </Row>
        </Col>
    </Container>
)
