
import React, { lazy, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from "./index.module.css";
import { Link } from 'react-router-dom';

const Sitemap = lazy(() => import('../../components/Sitemap'));

export default function NotFound() {

    const [showModal, setShowModal] = useState(false);



    return (
        <article>
            <Container fluid className={`${styles.pageContainer}`}>
                <Row className={`${styles.pagerow}`}>
                    <Col md={12} className={`${styles.Sitemapnofoundpage}`}>
                        <section className={`${styles.section_terms}`}>
                            <h1>Oh no!</h1>
                            <p>
                                Sorry, you have reached the page which has either been moved or is under update.</p>

                            <p>If you are seeing this error, please write us at <a

                                href="mailto:teamlowrates@swmc.com"
                            >
                                teamlowrates@swmc.com
                            </a> and let us know the page you were trying to access when the error occurred. We apologize for the inconvenience and thank you for your interest in LowRates.com (NMLS ID 3277) by Sun West Mortgage Company, Inc.</p>

                            <p>Please click <Link to='/'>http://lowrates.com</Link> to go back to the homepage or navigate to one of the web pages below.
                            </p>
                        </section>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} style={{ padding: '10px 30px' }} className={`${styles.Sitemapnofoundpage}`}>
                        <h4><b>Were you looking for one of these pages ?</b></h4>
                    </Col>

                    <Col md={12} className={`${styles.Sitemapnofoundpage}`}>
                    <div className={`${styles.Sitemapnofoundpagediv}`}><Sitemap /></div>
                    </Col>
                </Row>

            </Container>

            

            

        </article>
    );
}
