import React, { Suspense } from "react";
import { Helmet } from 'react-helmet';
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from '../Homepage';
import About from '../About';
import LoanProgramsConventional from '../LoanProgramsConventional';
import LoanProgramsFHA from '../LoanProgramsFHA';
import LoanProgramsInvestment from '../LoanProgramsInvestment';
import LoanProgramsJumbo from '../LoanProgramsJumbo';
import LoanProgramsRenovation from '../LoanProgramsRenovation';
import LoanProgramsRefinancing from '../LoanProgramsRefinancing';
import LoanProgramsHecm from '../LoanProgramsHecm';
import LoanProgramsVA from '../LoanProgramsVA';
import HomeBuyersGuide from '../HomeBuyersGuide';
import HomeBuyersRefinancing from '../HomeBuyersRefinancing';
import HomeBuyersDocuments from '../HomeBuyersDocuments';
import HomeBuyersMortgage from '../HomeBuyersMortgage';
import TermsOfUse from '../TermsOfUse';
import PrivacyPolicy from '../PrivacyPolicy';
import ContactUs from '../ContactUs';
import NewFaq from '../NewFaq';
import Calculators from '../Calculators';
import Rates from '../Rates';
import Sitemap from '../SiteMap';
import GetRates from '../GetMyRates';
import MortgageProcess from '../MortagageProcess';
import FixedRateCalculators from '../FixedRateCalculator';
import AffordabilityCalculator from '../AffordabilityCalculator';
import RefinanceCalculator from '../RefinanceCalculator';
import RentCalculator from '../RentCalculator';
import BiweeklyCalculator from '../BiweeklyCalculator';
import Disclaimer from '../Disclaimer';
import Notfoundpage from '../Notfoundpage';
import Spinner from "react-bootstrap/Spinner";

const App = () => {
    return (
        <div>
            <Suspense fallback={<div className="text-center"><Spinner /></div>}>
                <Routes>
                    <Route path='/' element={<Homepage />} />
                    <Route path="/*" element={<Notfoundpage />} />
                    <Route path='/about-us' element={<About />} />
                    <Route path='/conventional-loans' element={<LoanProgramsConventional />} />
                    <Route path='/fha-loans' element={<LoanProgramsFHA />} />
                    <Route path='/investment-loans' element={<LoanProgramsInvestment />} />
                    <Route path='/jumbo-loans' element={<LoanProgramsJumbo />} />
                    <Route path='/renovation-loans' element={<LoanProgramsRenovation />} />
                    <Route path='/refinance' element={<LoanProgramsRefinancing />} />
                    <Route path='/reverse-mortgage' element={<LoanProgramsHecm />} />
                    <Route path='/va-loans' element={<LoanProgramsVA />} />
                    <Route path='/terms' element={<TermsOfUse />} />
                    <Route path='/privacy' element={<PrivacyPolicy />} />
                    <Route path='/faq' element={<NewFaq />} />
                    <Route path='/home-buying' exact element={<HomeBuyersGuide />} />
                    <Route path='/refinancing-guide' exact element={<HomeBuyersRefinancing />} />
                    <Route path='/documents' exact element={<HomeBuyersDocuments />} />
                    <Route path='/mortgage-calculators' exact element={<HomeBuyersMortgage />} />
                    <Route path='/contact' element={<ContactUs />} />
                    <Route path='/calculators' element={<Calculators />} />
                    <Route path='/sitemap' exact element={<Sitemap />} />
                    <Route path='/fixed-calculator' exact element={<FixedRateCalculators />} />
                    <Route path='/refinance-calculator' exact element={<RefinanceCalculator />} />
                    <Route path='/affordability-calculator' exact element={<AffordabilityCalculator />} />
                    <Route path='/rent-vs-buy' exact element={<RentCalculator />} />
                    <Route path='/biweeklycalculator' exact element={<BiweeklyCalculator />} />
                    <Route path='/mortgage-process' element={<MortgageProcess />} />
                    <Route path='/disclaimers' exact element={<Disclaimer />} />
                </Routes>
            </Suspense>
        </div>

    )
}

export default App;