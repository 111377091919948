import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./index.module.css";
import LeadCaptureForm from "../../components/LeadCaptureForm/";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  const imgCDNURL = process.env.REACT_APP_LOWRATES_RESOURCES;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <article>
      <Helmet>
        <title>Contact Us | LowRates</title>
        <meta name="description" content="LowRates, Mortgage Loans" />
        <meta
          name="keywords"
          content="LowRates, Mortgage, Loans, Swmc, SunWestMortgage, Mortgage Possible"
        />
      </Helmet>

      <div className={styles.contact_us_div}>
        <Container fluid className={styles.contact_us_container}>
          <Row>
            <Col md={12} className={styles.contact_us_column}>
              <div className={styles.shadowcontainer}>
                <iframe
                  title="maps"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2021.1183817955587!2d-118.026477!3d33.859577!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc064c65852e94cbd!2sSun%20West%20Mortgage%20Company%2C%20Inc.%20NMLS%20ID%203277!5e1!3m2!1sen!2sin!4v1633380055292!5m2!1sen!2sin"
                  width="100%"
                  height="400"
                  frameborder="0"
                  allowfullscreen=""
                  style={{ border: '0' }}
                  className={styles.responsiveIframe}
                ></iframe>
              </div>
            </Col>

          </Row>

          <Row>
            <Col md={12} className="mt-5 mb-3">
              <h4 className={styles.contactustext}>Looking For Good Properties</h4>
              <h1 className={styles.contactustextbigger}>GET IN TOUCH</h1>
            </Col>
          </Row>

          <Row className={styles.contactInfoRow}>
            <Col md={4}>
              <div className={styles.contactInfoColumn}>
                <img src={imgCDNURL + "homeicon.webp"} className={styles.icon_image} />
                <h5>ADDRESS:</h5>
                <a className={styles.info_block_anchor} href="https://goo.gl/maps/vmWafJ6pieBNEJ357" target="_blank">
                  18303 GRIDLEY RD., CERRITOS,
                  <br />CA 90703
                </a>
              </div>
            </Col>


            <Col md={4} className={styles.contactInfoColumn1}>
              <div className={styles.contactInfoColumn}>
                <img src={imgCDNURL + "mailicon.webp"} className={styles.icon_image} />
                <h5>EMAIL ADDRESS:</h5>
                <a
                  className={styles.info_block_anchor}
                  href="mailto:teamlowrates@swmc.com"
                >
                  teamlowrates@swmc.com
                </a>
              </div>
            </Col>

            {/* <img src={lineinbetween} className={styles.icon_image}/> */}

            <Col md={4}>
              <div className={styles.contactInfoColumn}>
                <img src={imgCDNURL + "phoneicon.webp"} className={styles.icon_image} />
                <h5>PHONE NO:</h5>
                <a
                  className={styles.info_block_anchor}
                  href="tel:+18445697283"
                >
                  Toll Free: 1-(844)-LOW-RATE
                </a>
              </div>
            </Col>
          </Row>



        </Container>
      </div>

      <div className={styles.contact_form_div}>
        <Container fluid className={styles.contact_form_container}>
          <Row>
            <Col md={12} className={`mt-3 mb-3 ${styles.contactformcolumn}`}>
              <LeadCaptureForm />
            </Col>
          </Row>
        </Container>
      </div>



    </article>
  );
};

export default ContactUs;
