/*
* Refinance Calculator Page
*/

import React, { useEffect, memo } from 'react';
import { Helmet } from 'react-helmet';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import styles from './index.module.css';

const key = 'refinanceCalculator';

export function RefinanceCalculators({
  username,
  loading,
  error,
  repos,
}) {

  const imgCDNURL = process.env.REACT_APP_LOWRATES_RESOURCES;
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const reposListProps = {
    loading,
    error,
    repos,
  };

  return (
    <article>
      <Helmet>

        <title>Refinance Calculator | LowRates</title>
        <meta name="description" content="LowRates, Mortgage Loans" />
        <meta name="keywords" content="LowRates, Mortgage, Loans, Swmc, SunWestMortgage, Mortgage Possible" />

      </Helmet>
      <div>
        <img
          src={imgCDNURL + "loanprogrambannerimage.webp"}
          className={styles.loanprogrambannerimage}

        />
        <img
          src={imgCDNURL + "loanprogrambannerimagemobile.webp"}
          className={styles.loanprogrambannerimagemobile}

        />
        <a href="https://seemyloanstatus.com/ApplyNow/?c=lowrates" target="_blank" title="Click Here To ApplyNow">
          <img
            src={imgCDNURL + "applynowbanner.webp"}
            className={styles.applynowbanner}
          />
        </a>
        <div className={styles.reficalculatorStyleDiv}>
          <Container fluid className={styles.pageContainer}>
            <Row className={styles.header}>
              <h1>Refinance Calculator</h1>
              <h2>Simple, Fast Service.</h2>
            </Row>
            {/* implementing an iframe for calculator from an html page which is already created */}
            <div className={styles.iFrameDiv}>
              <iframe src=" https://swmc-landingpages.s3.amazonaws.com/mortgage-calculators/Swmc-calculators/refinance-calculator.html" height="1880px" overflow="hidden" className={styles.iFrame}></iframe>
            </div>
          </Container>
        </div>
      </div>


    </article>
  );
}

export default RefinanceCalculators
