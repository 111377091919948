import React, { useEffect, useRef, useState } from 'react';
import Classes from '../../containers/LoanPrograms/index.module.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { NavLink, Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import BlueButton from '../../components/BlueButton';
import ResourcesMenu from '../../components/ResourcesMenu';
import HowItWorks from '../../components/HowItWorks';
import WhiteButton from '../../components/WhiteButton';
import { Helmet } from 'react-helmet';


const NavWrapper = styled.div`
.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    color: #00AAE4;
    background-color: transparent;
    // border-bottom:1px solid #3074b7;
    padding: 0.8rem 1rem;
    border-radius: 0!important;

}
.nav-link {
    display: block;
    font-size: 20.1px;
    font-weight: 800;
    padding: 0.8rem 1rem;
    color: #083F88;
    border-radius: 0!important;
}

@media(min-width: 1200px)and (max-width:1400px) {
    .nav-link {
        font-size: 18.1px;
    }
}

@media(min-width: 992px)and (max-width:1199.7px) {
    .nav-link {
        font-size: 15.1px;
        font-weight: 900;
    }
}

@media(min-width: 768px)and (max-width:991.7px) {
    .nav-link {
        font-size: 12.7px;
        padding: 0.5rem 0.5rem;
        font-weight: 900;
    }
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
        padding: 0.5rem 0.5rem;
    }
}

@media(max-width:767px){
    .nav-link {
        font-size: 15.5px;
        padding: 0.5rem 0.5rem;
    }
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
        padding: 0.5rem 0.5rem;
    }
}
`

const routeKeys = {
    conventional: "conventional",
    fha: "fha",
    investment: "investment",
    jumbo: "jumbo",
    renovation: "renovation",
    refinancing: "refinancing",
    reverse: "reverse",
    va: "va"
}

const LoanPrograms = ({ history }) => {
    const [key, setKey] = useState('fha');
    const [navItemClicked, setNavItemClicked] = useState(false);
    const imgCDNURL = process.env.REACT_APP_LOWRATES_RESOURCES;
    let { eventKey } = useParams();
    const mainContentRef = useRef(null);

    useEffect(() => {
        if (mainContentRef.current) {
            window.scrollTo({
                top: mainContentRef.current.offsetTop,
                behavior: "smooth"
            });
        }
        setNavItemClicked(false);
    }, []);

    const handleNavItemClick = (selectedKey) => {
        setKey(selectedKey);
        setNavItemClicked(true);
        if (mainContentRef.current) {
            window.scrollTo({
                top: mainContentRef.current.offsetTop,
                behavior: "smooth"
            });
        }
    };


    return (
        <article style={{ backgroundColor: '#FCFCFC' }}>

            <Helmet>

                <title>FHA Loans | LowRates</title>
                <meta name="description" content="LowRates, Mortgage Loans" />
                <meta name="keywords" content="LowRates, Mortgage, Loans, Swmc, SunWestMortgage, Mortgage Possible" />

            </Helmet>

            <div className={Classes.loanProgramDiv}>

                <img
                    src={imgCDNURL + "loanprogrambannerimage.webp"}
                    className={Classes.loanprogrambannerimage}

                />

                <img
                    src={imgCDNURL + "loanprogrambannerimagemobile.webp"}
                    className={Classes.loanprogrambannerimagemobile}

                />

                <Container className={Classes.loanProgramContainer}>
                    <a href="https://seemyloanstatus.com/ApplyNow/?c=lowrates" target="_blank" title="Click Here To ApplyNow">
                        <img
                            src={imgCDNURL + "applynowbanner.webp"}
                            className={Classes.applynowbanner}
                        />
                    </a>
                    <NavWrapper>
                        <Tab.Container id="left-tabs-example" activeKey={key}
                            onSelect={handleNavItemClick}>
                            <Row >
                                <Col md={12} lg={12} sm={12} className={Classes.fixedLeft}>
                                    <h4 className={Classes.leftHeader}>Loan Services</h4>
                                    <Row>
                                        <Col md={6} lg={6} sm={12} >
                                            <Nav variant="pills" className={Classes.setNavLeft}>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.conventional} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
            if (e.ctrlKey) {
                window.open("/conventional-loans", "_blank");
            } else {
                handleNavItemClick(routeKeys.conventional);
                if (mainContentRef.current) {
                    window.scrollTo({
                        top: mainContentRef.current.offsetTop,
                        behavior: "smooth"
                    });
                }
            }
        }} to="/conventional-loans">CONVENTIONAL LOANS</Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey={routeKeys.fha} as={NavLink} to="/fha-loans" activeClassName={Classes.activePillLink} className={Classes.pillLink}>FHA LOANS</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.investment} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
                                                        if (e.ctrlKey) {
                                                            window.open("/investment-loans", "_blank");
                                                        } else {
                                                            handleNavItemClick(routeKeys.investment);
                                                        }
                                                    }} to="/investment-loans">INVESTMENT PROPERTIES</Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.jumbo} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink1} onClick={(e) => {
                                                        if (e.ctrlKey) {
                                                            window.open("/jumbo-loan", "_blank");
                                                        } else {
                                                            handleNavItemClick(routeKeys.jumbo);
                                                        }
                                                    }} to="/jumbo-loans">JUMBO LOANS</Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                        <Col md={6} lg={6} sm={12} >
                                            <Nav variant="pills" className={Classes.setNavLeft}>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.renovation} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
                                                        if (e.ctrlKey) {
                                                            window.open("/renovation-loans", "_blank");
                                                        } else {
                                                            handleNavItemClick(routeKeys.renovation);
                                                        }
                                                    }} to="/renovation-loans">RENOVATION AND CONSTRUCTION LOANS</Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.refinancing} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
                                                        if (e.ctrlKey) {
                                                            window.open("/refinance", "_blank");
                                                        } else {
                                                            handleNavItemClick(routeKeys.refinancing);
                                                        }
                                                    }} to="/refinance">REFINANCING</Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.reverse} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
                                                        if (e.ctrlKey) {
                                                            window.open("/reverse-mortgage", "_blank");
                                                        } else {
                                                            handleNavItemClick(routeKeys.reverse);
                                                        }
                                                    }} to="/reverse-mortgage">REVERSE MORTGAGE</Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.va} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink2} onClick={(e) => {
                                                        if (e.ctrlKey) {
                                                            window.open("/va-loans", "_blank");
                                                        } else {
                                                            handleNavItemClick(routeKeys.va);
                                                        }
                                                    }} to="/va-loans">VA LOANS</Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </NavWrapper>
                </Container>

                <div className={Classes.loanProgramContentDiv} ref={mainContentRef}>

                    <Container className={Classes.loanProgramContainer}>
                        <NavWrapper>
                            <Tab.Container id="left-tabs-example" activeKey={key}
                                onSelect={(k) => setKey(k)}>
                                <Row>

                                    <Col sm={12} lg={12} md={12} className={Classes.rightCol}>
                                        <h1 className={Classes.loanHeader}>
                                            {key === routeKeys.conventional && 'CONVENTIONAL LOANS'}
                                            {key === routeKeys.fha && 'FHA LOANS'}
                                            {key === routeKeys.investment && 'INVESTMENT PROPERTIES LOANS'}
                                            {key === routeKeys.jumbo && 'JUMBO LOANS'}
                                            {key === routeKeys.renovation && 'RENOVATION & CONSTRUCTION'}
                                            {key === routeKeys.refinancing && 'REFINANCING'}
                                            {key === routeKeys.reverse && 'REVERSE MORTGAGE / HECM LOANS'}
                                            {key === routeKeys.va && 'VA LOANS'}
                                        </h1>

                                        <div className={Classes.conventional}>


                                            <Tab.Pane eventKey={routeKeys.fha}>
                                                <img
                                                    src={imgCDNURL + "fhaloanimage.webp"}
                                                    className={Classes.loanpurposeimage}
                                                    alt="fha loan image"
                                                />
                                                <h3>What is an FHA loan?</h3>
                                                <p>FHA loans are insured by the Federal Housing Administration and can be a great option for people who would normally find it difficult to qualify for a mortgage due to their income level, credit history, or their ability to put down a sufficient amount of down payment. For borrowers with a credit score of 580 or higher, these loans allow a down payment of just 3.5% of the total cost of the home. For those with a credit score between 500 and 579, a 10% down payment is required.</p>
                                                <h3>What are the benefits?</h3>
                                                <p>An FHA loan is one of the most attainable home loans on the market. Geared towards helping first-time home buyers, these loans offer affordable down payments and less-strict credit score requirements for those who qualify. Whether you’re in the market for a new home or seeking financial assistance to help renovate your current one, FHA loans can offer funding for your residential goals.</p>
                                                <h3>What is required to receive an FHA loan?</h3>
                                                <p>Before applying for an FHA loan, it’s important to know what to expect throughout the process. Once approved for an FHA loan, it is required to have an appraisal and credit report performed. A closing cost may also be required, depending on whether the home seller chooses to cover the closing costs or not.</p>

                                                <h3>Why should you choose LowRates?</h3>
                                                <p>Our team offers a 24-hour loan center for future home owners who don't have time to apply for a loan during regular business hours. We offer quick loan approvals and provide our clients with the best loan options for their needs and financial situations. Our team members are well-versed in FHA loan rules and requirements and will ensure you are knowledgeable about our <Link to='/mortgage-process'>mortgage process</Link> prior to committing to a loan.</p>
                                                <p>For any questions about the FHA mortgage loan process or any of our other home loan services, give our team at LowRates a call to speak to a local team member who can answer all of your home loan questions. <Link to='/contact'>Contact Us Today!</Link></p>
                                            </Tab.Pane>


                                        </div>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </NavWrapper>

                    </Container>
                </div>
            </div>

            <div className={Classes.loanhowitworksDiv}>

                <Container className={Classes.loanhowitworksContainer}>
                    <HowItWorks />
                </Container>
            </div>

            <div className={Classes.loanresourcesDiv}>

                <Container className={Classes.loanresourcesContainer}>
                    <ResourcesMenu />
                </Container>
            </div>
        </article>
    )
}

export default LoanPrograms