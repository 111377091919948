import React from "react";
import Classes from './index.module.css'
const Inputs = (props) => {
    return (
        
        <div>
            <label style={{ fontWeight: '600' }}>{props.label}</label>


            {props.type === "textarea" ? <textarea className={Classes.textBoxStyle} name={props.name} {...props.register} placeholder={props.placeholder} rows="5" style={{ width: '100%' }} />
                :
                props.name === "Zipcode" || props.name === "City" || props.name === "zipCode" || props.name === "city" || props.name === "Loan_Number" || props.name === "SSN" ? <input className={Classes.inputStyle} type={props.type} name={props.name} {...props.register} placeholder={props.placeholder} /> :
                    props.name === "companyCode" ? <input className={Classes.inputStyle} type={props.type} name={props.name} {...props.register} value={props.value} placeholder={props.placeholder} style={{ textTransform: "uppercase" }} /> : <input className={Classes.inputStyle} type={props.type} name={props.name} {...props.register} value={props.value} placeholder={props.placeholder} />
            }
            {/* <input className={Classes.inputStyle} type={props.type} {...props.register} name={props.name} placeholder={props.placeholder} /> */}
            {/* <p> {props.errors[props.name]?.message}</p> */}
            {props.errors[props.name] && <p>{props.errors[props.name].message}</p>}
        </div>
    );
}
export default Inputs