/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */

/*
 * Footer used across all the pages
 */

import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./index.css";

export default function HowItWorks() {
    const imgCDNURL = process.env.REACT_APP_LOWRATES_RESOURCES;
    return (
        <div id="howItWorks-wrapper">
            <Container className="my-1">
                <Row>
                    <Col className="text-center mb-5 pb-1" lg={12}><h2 className="howitworksheading">How It Works</h2></Col>
                    <Col sm={12} md={6} lg={3} className="howItWorksCol">
                        <div className="layout-column">
                            <div className="content-boxes">
                                <div className="content-wrapper content-wrapper-boxed link-area-link-icon icon-hover-animation-fade" style={{backgroundColor: "rgba(255, 255, 255, 0)", overflow: "visible"}}>
                                    <div className="heading heading-with-icon icon-left text-center">
                                        <div className="image">
                                            <img src={imgCDNURL + "penimage.webp"} width="75" height="70" alt=""/>
                                        </div><h2 className="content-box-heading" style={{fontSize:"18px",lineHeight:"23px", fontWeight: "900"}}>Step One</h2>
                                    </div>
                                    <div className="content-container">
                                        <p className="newchangesneededforbutton">Use our online application to send in your request or contact our office via phone or email.</p>
                                        <a href="https://seemyloanstatus.com/ApplyNow/?c=lowrates" target="_blank" title="Click Here To ApplyNow"><img src={imgCDNURL + "applynowbutton.webp"} className="applynowbutton" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={3} className="howItWorksCol">
                        <div className="layout-column">
                            <div className="content-boxes">
                                <div className="content-wrapper content-wrapper-boxed link-area-link-icon icon-hover-animation-fade" style={{backgroundColor: "rgba(255, 255, 255, 0)", overflow: "visible"}}>
                                    <div className="heading heading-with-icon icon-left text-center">
                                        <div className="image">
                                            <img src={imgCDNURL + "twopeopleimage.webp"} width="75" height="70" alt=""/>
                                        </div><h2 className="content-box-heading" style={{fontSize:"18px",lineHeight:"23px", fontWeight: "900"}}>Step Two</h2>
                                    </div>
                                    <div className="content-container">
                                        <p>Once your application is complete, we’ll take a look at your family’s financial situation and home loan needs and then pair you with a list of mortgage options.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={3} className="howItWorksCol">
                        <div className="layout-column">
                            <div className="content-boxes">
                                <div className="content-wrapper content-wrapper-boxed link-area-link-icon icon-hover-animation-fade" style={{backgroundColor: "rgba(255, 255, 255, 0)", overflow: "visible"}}>
                                    <div className="heading heading-with-icon icon-left text-center">
                                        <div className="image">
                                            <img src={imgCDNURL + "homeiconimage.webp"} width="75" height="70" alt=""/>
                                        </div><h2 className="content-box-heading" style={{fontSize:"18px",lineHeight:"23px", fontWeight: "900"}}>Step Three</h2>
                                    </div>
                                    <div className="content-container">
                                        <p>Review and discuss your loan options with your loan officer to make sure you choose the right loan for your needs.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={3} className="howItWorksCol">
                        <div className="layout-column">
                            <div className="content-boxes">
                                <div className="content-wrapper content-wrapper-boxed link-area-link-icon icon-hover-animation-fade" style={{backgroundColor: "rgba(255, 255, 255, 0)", overflow: "visible"}}>
                                    <div className="heading heading-with-icon icon-left text-center">
                                        <div className="image">
                                            <img src={imgCDNURL + "keyiconimage.webp"} width="75" height="70" alt=""/>
                                        </div><h2 className="content-box-heading" style={{fontSize:"18px",lineHeight:"23px", fontWeight: "900"}}>Step Four</h2>
                                    </div>
                                    <div className="content-container">
                                        <p>Once your loan is chosen and your application is accepted, you are ready to close!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
