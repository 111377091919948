/*
 * Site Map Page
 *
 */
import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Classes from './index.module.css';
import { Helmet } from 'react-helmet';
import Sitemap from '../../components/Sitemap'

const SiteMap = () => {
 
  return (
    <article>
          <Helmet>
            
          <title>Sitemap | LowRates</title>
          <meta name="description" content="LowRates, Mortgage Loans"/>
          <meta name="keywords" content="LowRates, Mortgage, Loans, Swmc, SunWestMortgage, Mortgage Possible"/>


         </Helmet>
      
      <div>
        <div
          className={Classes.hero_image}
          style={{ color: '#fff', paddingTop: '10%', paddingLeft: '3%' }}
        >
          <h1>Site Map</h1>
        </div>
        <Container fluid={true} className={Classes.pageContainer}>

          <Sitemap/>
                  
        </Container>
      </div>
    </article>
  );
};
export default SiteMap;
