import React, { useEffect, useState } from 'react';
import styles from './index.module.css'
import { Container, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Calculators = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])


    return (
        <article>
            {/* <Helmet>

                <title>Mortgage Calculators | LowRates</title>
                <meta name="description" content="LowRates, Mortgage Loans" />
                <meta name="keywords" content="LowRates, Mortgage, Loans, Swmc, SunWestMortgage, Mortgage Possible" />

            </Helmet> */}
            <Container className={styles.calculators_container}>
                <div className={styles.title}>
                    <h1>Mortgage Calculators</h1>

                    <Col md={12}>
                        <h4>Calculators to Help You Decide</h4><br />

                        <p>Using a mortgage calculator is a good way to see what your financing options are and how much you can afford before actually applying for a loan. When you have compared and figured out your options, request a quote, get pre-qualified, get pre-approved, or apply for a Loan; the process is simple. If you have more questions or prefer to speak to a live agent to help you with the numbers, please don’t hesitate to call us at <a href="tel:+18557862437">855-786-2437</a> and we will be more than happy to assist you.</p><br />

                        <h5><Link to="/fixed-calculator">Fixed-Rate Mortgage Calculator</Link></h5><br />

                        <p>With a fixed rate mortgage, you make the same amount toward your monthly payments in principal and interest for the life of the loan. Find out what your monthly payments will be with various down payment amounts, terms, and interest rates. You will be able to see a full payment schedule with the results.</p><br />

                        <h5><Link to="/refinance-calculator">Refinance Calculator</Link></h5><br />

                        <p>With a refinance, you may be able to benefit from savings from a lower interest rate or shorter loan term. But depending on how much or how you would like to refinance, your amount of savings may vary. Use our refinance calculator to get a quick estimate on how much it would cost to refinance your mortgage and whether this option makes sense for you.</p><br />

                        <h5><Link to="/affordability-calculator">Affordability Calculator</Link></h5><br />

                        <p>With our affordability calculator, you will have an idea of the price of the home you will be able to afford while considering your monthly personal and home-related expenses. The calculator will tell you if your current income can support the home you want so you can decide whether you should consider a different home or other financing options.</p><br />

                        <h5><Link to="/rent-vs-buy">Rent Vs. Buy Calculator</Link></h5><br />

                        <p>Many first time homebuyers or renters may be wondering if it is overall more or less expensive to buy a home. When comparing renting versus buying a home, there are many variables that must be taken into consideration in order make the right decision. Using our Rent vs. Buy calculator, you will have a better idea whether it makes economic sense to buy and how long you will need to stay in your purchased home in order to realize the benefits.

                        </p><br />

                        <h5><Link to="/biweeklycalculator">Biweekly Payment Calculator</Link></h5><br />

                        <p>With increased myth and misconceptions associated with biweekly mortgage payments, we are offering this calculator as a tool to help our borrowers compare their mortgage payment options. While this calculator is geared more towards current homeowners with an existing mortgage, it can also be useful for new borrowers. Before using this calculator, we suggest reading about the Biweekly Payment Option Explained in order to understand the various biweekly payment options available.</p>

                    </Col>
                </div>

            </Container>
        </article>
    );
}

export default Calculators;