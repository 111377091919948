import React, { useEffect, useRef, useState } from 'react';
import Classes from '../../containers/LoanPrograms/index.module.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { NavLink, Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import BlueButton from '../../components/BlueButton';
import ResourcesMenu from '../../components/ResourcesMenu';
import HowItWorks from '../../components/HowItWorks';
import WhiteButton from '../../components/WhiteButton';
import { Helmet } from 'react-helmet';


const NavWrapper = styled.div`
.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    color: #00AAE4;
    background-color: transparent;
    // border-bottom:1px solid #3074b7;
    padding: 0.8rem 1rem;
    border-radius: 0!important;

}
.nav-link {
    display: block;
    font-size: 20.1px;
    font-weight: 800;
    padding: 0.8rem 1rem;
    color: #083F88;
    border-radius: 0!important;
}

@media(min-width: 1200px)and (max-width:1400px) {
    .nav-link {
        font-size: 18.1px;
    }
}

@media(min-width: 992px)and (max-width:1199.7px) {
    .nav-link {
        font-size: 15.1px;
        font-weight: 900;
    }
}

@media(min-width: 768px)and (max-width:991.7px) {
    .nav-link {
        font-size: 12.7px;
        padding: 0.5rem 0.5rem;
        font-weight: 900;
    }
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
        padding: 0.5rem 0.5rem;
    }
}

@media(max-width:767px){
    .nav-link {
        font-size: 15.5px;
        padding: 0.5rem 0.5rem;
    }
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
        padding: 0.5rem 0.5rem;
    }
}
`

const routeKeys = {
    conventional: "conventional",
    fha: "fha",
    investment: "investment",
    jumbo: "jumbo",
    renovation: "renovation",
    refinancing: "refinancing",
    reverse: "reverse",
    va: "va"
}

const LoanPrograms = ({ history }) => {
    const [key, setKey] = useState('reverse');
    const imgCDNURL = process.env.REACT_APP_LOWRATES_RESOURCES;
    const [navItemClicked, setNavItemClicked] = useState(false);
    let { eventKey } = useParams();
    const mainContentRef = useRef(null);

    useEffect(() => {
        if (mainContentRef.current) {
            window.scrollTo({
                top: mainContentRef.current.offsetTop,
                behavior: "smooth"
            });
        }
        setNavItemClicked(false);
    }, []);

    const handleNavItemClick = (selectedKey) => {
        setKey(selectedKey);
        setNavItemClicked(true);
        if (mainContentRef.current) {
            window.scrollTo({
                top: mainContentRef.current.offsetTop,
                behavior: "smooth"
            });
        }
    };

    return (
        <article style={{ backgroundColor: '#FCFCFC' }}>

            <Helmet>

                <title>Reverse Mortgage | LowRates</title>
                <meta name="description" content="LowRates, Mortgage Loans" />
                <meta name="keywords" content="LowRates, Mortgage, Loans, Swmc, SunWestMortgage, Mortgage Possible" />

            </Helmet>

            <div className={Classes.loanProgramDiv}>

                <img
                    src={imgCDNURL + "loanprogrambannerimage.webp"}
                    className={Classes.loanprogrambannerimage}

                />

                <img
                    src={imgCDNURL + "loanprogrambannerimagemobile.webp"}
                    className={Classes.loanprogrambannerimagemobile}

                />

                <Container className={Classes.loanProgramContainer}>
                    <a href="https://seemyloanstatus.com/ApplyNow/?c=lowrates" target="_blank" title="Click Here To ApplyNow">
                        <img
                            src={imgCDNURL + "applynowbanner.webp"}
                            className={Classes.applynowbanner}
                        />
                    </a>
                    <NavWrapper>
                        <Tab.Container id="left-tabs-example" activeKey={key}
                            onSelect={handleNavItemClick}>
                            <Row>
                                <Col md={12} lg={12} sm={12} className={Classes.fixedLeft}>
                                    <h4 className={Classes.leftHeader}>Loan Services</h4>
                                    <Row>
                                        <Col md={6} lg={6} sm={12} >
                                            <Nav variant="pills" className={Classes.setNavLeft}>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.conventional} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
                                                        if (e.ctrlKey) {
                                                            window.open("/conventional-loans", "_blank");
                                                        } else {
                                                            handleNavItemClick(routeKeys.conventional);
                                                        }
                                                    }} to="/conventional-loans">CONVENTIONAL LOANS</Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.fha} as={NavLink} to="/fha-loans" onClick={(e) => {
                                                        if (e.ctrlKey) {
                                                            window.open("/fha-loans", "_blank");
                                                        } else {
                                                            handleNavItemClick(routeKeys.fha);
                                                        }
                                                    }} activeClassName={Classes.activePillLink} className={Classes.pillLink}>FHA LOANS</Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.investment} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
                                                        if (e.ctrlKey) {
                                                            window.open("/investment-loans", "_blank");
                                                        } else {
                                                            handleNavItemClick(routeKeys.investment);
                                                        }
                                                    }} to="/investment-loans">INVESTMENT PROPERTIES</Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.jumbo} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink1} onClick={(e) => {
                                                        if (e.ctrlKey) {
                                                            window.open("/jumbo-loan", "_blank");
                                                        } else {
                                                            handleNavItemClick(routeKeys.jumbo);
                                                        }
                                                    }} to="/jumbo-loans">JUMBO LOANS</Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                        <Col md={6} lg={6} sm={12} >
                                            <Nav variant="pills" className={Classes.setNavLeft}>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.renovation} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
                                                        if (e.ctrlKey) {
                                                            window.open("/renovation-loans", "_blank");
                                                        } else {
                                                            handleNavItemClick(routeKeys.renovation);
                                                        }
                                                    }} to="/renovation-loans">RENOVATION AND CONSTRUCTION LOANS</Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.refinancing} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} onClick={(e) => {
                                                        if (e.ctrlKey) {
                                                            window.open("/refinance", "_blank");
                                                        } else {
                                                            handleNavItemClick(routeKeys.refinancing);
                                                        }
                                                    }} to="/refinance">REFINANCING</Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey={routeKeys.reverse} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink} to="/reverse-mortgage">REVERSE MORTGAGE</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Link eventKey={routeKeys.va} as={NavLink} activeClassName={Classes.activePillLink} className={Classes.pillLink2} onClick={(e) => {
                                                        if (e.ctrlKey) {
                                                            window.open("/va-loans", "_blank");
                                                        } else {
                                                            handleNavItemClick(routeKeys.va);
                                                        }
                                                    }} to="/va-loans">VA LOANS</Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </NavWrapper>
                </Container>

                <div className={Classes.loanProgramContentDiv} ref={mainContentRef}>

                    <Container className={Classes.loanProgramContainer}>
                        <NavWrapper>
                            <Tab.Container id="left-tabs-example" activeKey={key}
                                onSelect={(k) => setKey(k)}>
                                <Row>

                                    <Col sm={12} lg={12} md={12} className={Classes.rightCol}>
                                        <h1 className={Classes.loanHeader}>
                                            {key === routeKeys.conventional && 'CONVENTIONAL LOANS'}
                                            {key === routeKeys.fha && 'FHA LOANS'}
                                            {key === routeKeys.investment && 'INVESTMENT PROPERTIES LOANS'}
                                            {key === routeKeys.jumbo && 'JUMBO LOANS'}
                                            {key === routeKeys.renovation && 'RENOVATION & CONSTRUCTION'}
                                            {key === routeKeys.refinancing && 'REFINANCING'}
                                            {key === routeKeys.reverse && 'REVERSE MORTGAGE / HECM LOANS'}
                                            {key === routeKeys.va && 'VA LOANS'}
                                        </h1>

                                        <div className={Classes.conventional}>
                                            <Tab.Pane eventKey={routeKeys.reverse}>
                                                <img
                                                    src={imgCDNURL + "hecmloanimage.webp"}
                                                    className={Classes.loanpurposeimage}
                                                    alt="hecm loan image"
                                                />
                                                <h3>What is a Reverse Mortgage?</h3>
                                                <p>A reverse mortgage is a type of home equity loan that allows you to convert part of the equity in your home into cash without having to sell your home or pay additional monthly bills. Reverse mortgages are typically catered towards older homeowners , but they have also become a great retirement planning tool for many homeowners . This type of mortgage does not need to be repaid until the borrower passes away, sells the home, or permanently moves out. Borrower must maintain the house and pay property taxes and homeowners  insurance.</p>
                                                <p>Home Equity Conversion Mortgage (HECM) is a type of reverse mortgage insured by FHA.</p>
                                                <h3>How do Reverse Mortgage work?</h3>
                                                <p>Unlike a traditional mortgage that borrowers pay back each month, a reverse mortgage makes payments to borrowers. Borrowers can get these payments in a lump sum, as regular monthly payments, as a line of credit, or as a combination of monthly payments with a line of credit. There are several factors that determine the amount of funds you can receive from a reverse mortgage, such as your age, value of home, and interest rate.</p>
                                                <h3>Who would benefit from a Reverse Mortgage?</h3>
                                                <p>A reverse mortgage makes senses for individuals who:</p>
                                                <ul>
                                                    <li>Are age 62 and older who own a home or have small mortgages.</li>
                                                    <li>Don’t plan to move.</li>
                                                    <li>Can afford the cost of maintaining their home</li>
                                                    <li>Want to access the equity in their home to supplement their income or have money available for a rainy day.</li>
                                                </ul>
                                                <h3>What are the advantages of Reverse Mortgage?</h3>
                                                <ul>
                                                    <li>Does not require monthly payments from the borrower.</li>
                                                    <li>Proceeds can be used to pay off debt or settle unexpected expenses.</li>
                                                    <li>Funds can improve monthly cash flow.</li>
                                                </ul>

                                                <h3>Why should you choose LowRates?</h3>
                                                <p>Our team offers a 24-hour loan center for future homeowners  who don't have time to apply for a loan during regular business hours. We offer quick loan approvals and provide our clients with the best loan options for their needs and financial situations. Our team members are well-versed in Reverse Mortgage rules and requirements and will ensure you are knowledgeable about our <Link to='/mortgage-process'>mortgage process</Link> prior to committing to a loan.</p>
                                                <p>For any questions about the Reverse Mortgage process or any of our other home loan services, give our team at LowRates a call to speak to a local team member who can answer all of your home loan questions. <Link to='/contact'>Contact Us Today!</Link></p>

                                            </Tab.Pane>
                                        </div>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </NavWrapper>

                    </Container>
                </div>
            </div>

            <div className={Classes.loanhowitworksDiv}>

                <Container className={Classes.loanhowitworksContainer}>
                    <HowItWorks />
                </Container>
            </div>

            <div className={Classes.loanresourcesDiv}>

                <Container className={Classes.loanresourcesContainer}>
                    <ResourcesMenu />
                </Container>
            </div>
        </article>
    )
}

export default LoanPrograms