import { Container, Col, Row } from 'react-bootstrap';

export const ContactSection = ({ styles }) => (
    <Container className={styles.contact_section + ' ' + styles.padded}>
        <Row className={styles.contact_section_row}>
            <Col lg={4} md={4} sm={4} xs={4} className={`${styles.vertical_middle} ${styles.contact_section_left_col}`}>
                <div className={styles.padded}>
                    To start our sharing with non-affiliates or to stop our sharing with affiliates for marketing purposes
                </div>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
                <div>
                    <ul>
                        <li>Call us at 800.453.7884</li>
                        <li>
                            Write to us at <a href="mailto:privacy@swmc.com">privacy@swmc.com</a>
                        </li>
                        <li>Mail us one or both of the <a href="https://www.swmc.com/swmc/downloads/Non-State specific - English - Tear-off Mail-in form.pdf" target="_blank" rel="noreferrer">"Mail-in Forms"</a>.&nbsp;
                            One form is to authorize our sharing information with non-affiliates; the second form is to stop us from sharing information with affiliates for marketing purposes.
                        </li>
                    </ul>
                    <h2>Please note:</h2>
                    <p>
                        If you are a new customer, we can begin sharing your information 30 days from the date we sent this notice.&nbsp;
                        When you are no longer our customer, we can continue to share your information for up to 18 months after you&nbsp;
                        are no longer our customer.
                    </p>
                    <p>However, you can contact us at any time to limit our sharing.</p>
                </div>
            </Col>
        </Row>
    </Container>
)
