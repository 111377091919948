const nameReducer = (state = {
    type: '',
    purchasePrice: '',
    downPaymentInUSDs: '',
    downPaymentInPercents: '',
    CreditScore: '',
    ShowButtons: true,
    propertyType: '',
    propertyUse: "",
    lowRates: undefined,
    lowRatesOption: undefined,
    estimatedValue: '',
    loanBalance: '',
    loanAmount: '',
    estimatedHomeValue: '',
    cashoutAmount: "",
	zipCode: "",
	countryState: "Select a State",
    stateCounty: "",
    stateCountyName: "",
	veteran: "",
    stateList: ""
}, action) => {
    switch (action.type) {
        case "TYPESELECTED":
            return { ...state, type: action.payload }
            break;
        case "PURCHASEPRICEUPDATE":
            return { ...state, purchasePrice: action.payload }
            break;
        case "DOWNPAYMENTINUSDSUPDATE":
            return { ...state, downPaymentInUSDs: action.payload }
            break;
        case "DOWNPAYMENTINPERCENTSSUPDATE":
            return { ...state, downPaymentInPercents: action.payload }
            break;
        case "CREDITSCOREUPDATE":
            return { ...state, CreditScore: action.payload }
            break;
        case "SHOWBUTTONSUPDATE":
            return { ...state, ShowButtons: action.payload }
            break;
        case "PROPERTYTYPEUPDATE":
            return { ...state, propertyType: action.payload }
            break;
        case "PROPERTYUSEDUPDATE":
            return { ...state, propertyUse: action.payload }
            break;
        case "LOWRATESUPDATE":
            return { ...state, lowRates: action.payload }
            break;
        case "LOWRATESOPTION":
            return { ...state, lowRatesOption: action.payload }
            break;
        case "ESTIMATEDVALUEUPDATED":
            return { ...state, estimatedValue: action.payload }
            break;
        case "LOANBALANCEUPDATED":
            return { ...state, loanBalance: action.payload }
            break;
        case "CASHOUTAMOUNTUPDATED":
            return { ...state, cashoutAmount: action.payload }
            break;
        case "ESTIMATEDHOMEVALUEUPDATED":
            return { ...state, estimatedHomeValue: action.payload }
            break;
        case "LOANAMOUNTTOTALUPDATE":
            return { ...state, loanAmount: action.payload }
            break;
        case "ZIPCODEUPDATE":
            return { ...state, zipCode: action.payload }
            break;
        case "COUNTRYSTATEUPDATE":
            return { ...state, countryState: action.payload }
            break;
		case "STATECOUNTYUPDATE":
            return { ...state, stateCounty: action.payload }
            break;
        case "STATECOUNTYNAMEUPDATE":
            return { ...state, stateCountyName: action.payload }
            break;
		case "VETERANUPDATE":
            return { ...state, veteran: action.payload }
            break;
        case "STATELISTUPDATE":
            return { ...state, stateList: action.payload }
            break;
        default:
            return state
            break;
    }

}
export default nameReducer